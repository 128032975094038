import { Button } from 'antd'
import React from 'react'
import LayoutTemplate from '../components/layout/Layout'

interface Props { }

function Home(props: Props) {
    const { } = props

    return (
        <LayoutTemplate>
        <div>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid molestiae blanditiis delectus dolores? Incidunt, laborum ratione vel minus architecto, sint facere hic nobis earum totam neque doloremque doloribus! Animi, quis?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid molestiae blanditiis delectus dolores? Incidunt, laborum ratione vel minus architecto, sint facere hic nobis earum totam neque doloremque doloribus! Animi, quis?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid molestiae blanditiis delectus dolores? Incidunt, laborum ratione vel minus architecto, sint facere hic nobis earum totam neque doloremque doloribus! Animi, quis?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid molestiae blanditiis delectus dolores? Incidunt, laborum ratione vel minus architecto, sint facere hic nobis earum totam neque doloremque doloribus! Animi, quis?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid molestiae blanditiis delectus dolores? Incidunt, laborum ratione vel minus architecto, sint facere hic nobis earum totam neque doloremque doloribus! Animi, quis?
        </div>
        </LayoutTemplate>
    )
}

export default Home
