import { UserInterface, WorkPlaceDetails } from "../../interfaces/user.interface";
import { UserInterfaceServerResponse } from "./users";
import { SERVER_TIPS_AUTHOR_VIEW_ENDPOINT } from "../../config/endpoints";

const mapLocation = (city: string, country: string) => {
    let location = "N/A";
    if(city && country){
        location = `${city} , ${country}`;
    } else if (city){
        location = city;
    }else if(country) {
        location = country
    }
    return location;
}

const mapWorkPlace = (workplaces: Array<WorkPlaceDetails> | undefined) => {
    const workplace = workplaces && workplaces?.length > 0 ? workplaces.shift() : null;
    let workDetails = "N/A";

    if (workplace) {
        if (workplace.title && workplace.company_name) {
            workDetails = `${workplace.title}, ${workplace.company_name}`;
        } else if (workplace.title) {
            workDetails = workplace.title;
        } else if (workplace.company_name) {
            workDetails = workplace.company_name;
        }
    }
    
    return workDetails;
}

const mapAuthorUrl = (username: string) => {
    return SERVER_TIPS_AUTHOR_VIEW_ENDPOINT.replace(':username', username);
}

export const mapUserFromServerToClient = (user:UserInterfaceServerResponse): UserInterface => {
return {
    name: user.name,
    username: user.username,
    avatar: user.avatar,
    email: user.email,
    location: mapLocation(user.city, user.country),
    workplace: mapWorkPlace(user.workplaces),
    counts: user.counts,
    social_info: user.social_info,
    is_active: user.is_active,
    authorUrl: mapAuthorUrl(user.username),
}
}