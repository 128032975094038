import {
  SERVER_TIPS_ADMIN_CATEGORY_ENDPOINT
  } from '../../config/endpoints';
import { axioService, GET, POST } from '../../services/axiosService';
import { getAuthToken } from '../../services/authentication';
import { ServerCategoryObj } from './dataTypesd';
import {categoryMapper} from "./mapper";
  
class Category {
  getCategories = async () => {
    const token = getAuthToken();

    const response = await axioService(
      GET,
      SERVER_TIPS_ADMIN_CATEGORY_ENDPOINT,
      {},
      token
    );

    return response?.data.data.map((category: ServerCategoryObj, index: number) => {
      return categoryMapper(category);
    });
  };
}
  
  export default new Category();
  