import { TipsInterface, TrashedTipsInterface } from "../../interfaces/tips.interface";
import { TipsInterfaceServerResponse, TrashedTipsInterfaceServerResponse } from "./contents"

  export const mapTipsListFromServerToClient = (tipsList: Array<TipsInterfaceServerResponse>) => {
    return tipsList.map((tips: TipsInterfaceServerResponse): TipsInterface => {
      return {  
        tipsId: tips.id,
        tipsTitle: tips.title,
        tipsSlug: tips.slug,
        authorName: tips.author?.name,
        authorUsername: tips.author?.username,
        authorAvatar: tips.author?.avatar,
        enrollmentCount: tips.counts?.enrollments,
        publishedAt: tips.published_at,
        last_enrollment: tips.last_enrollment,
        tipsStatus: tips.status,
        tipsCategory: tips.category,
    }
    });
}

export const mapTrashedTipsListFromServerToClient = (deletedTipsList: Array<TrashedTipsInterfaceServerResponse>) => {
  return deletedTipsList.map((deletedTips: TrashedTipsInterfaceServerResponse): TrashedTipsInterface => {
    return {  
      tipsId: deletedTips.id,
      tipsTitle: deletedTips.title,
      tipsSlug: deletedTips.slug,
      authorName: deletedTips.author?.name,
      authorUsername: deletedTips.author?.username,
      authorAvatar: deletedTips.author?.avatar,
      enrollmentCount: deletedTips.counts?.enrollments,
      publishedAt: deletedTips.published_at,
      last_enrollment: deletedTips.last_enrollment,
      tipsStatus: deletedTips.status,
      tipsCategory: deletedTips.category,
      adminName: deletedTips.deleted_by?.name,
      adminUsername: deletedTips.deleted_by?.username,
      adminAvatar: deletedTips.deleted_by?.avatar,
  }
  });
}

export const mapTipsFromServerToClient = (tips: TipsInterfaceServerResponse): TipsInterface => {
  return {
    tipsId: tips.id,
    tipsTitle: tips.title,
    tipsSlug: tips.slug,
    authorName: tips.author?.name,
    authorUsername: tips.author?.username,
    authorAvatar: tips.author?.avatar,
    enrollmentCount: tips.counts?.enrollments,
    publishedAt: tips.published_at,
    tipsStatus: tips.status,
  }
}