import React, {useEffect, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "..//../components/layout/Layout";
import "./style.scss";
import styles from "../Contents/Contents.module.scss";
import { PageHeader, Button, Select, Input, Avatar, message, Checkbox, Pagination, Modal, DatePicker } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Content from "../../api/content/request";
import Category from '../../api/categories/request'
import { TrashedTipsInterface } from "../../interfaces/tips.interface";
import moment from "moment";

const { RangePicker }: any = DatePicker;

function TrashedTips () {

    const { TextArea } = Input;
    const history = useHistory();
    const location = useLocation();
    const [deletedTipsList, setDeletedTipsList] = useState<TrashedTipsInterface[] | null | undefined>([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [slug, setSlug] = useState('');
    const [trashTipsSlug, setTrashTipsSlug] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [searchKey, setSearchKey] = useState('');
    const [restoreModalVisible, setRestoreModalVisible] = useState(false);
    const [trashModalVisible, setTrashModalVisible] = useState(false);
    const [modalTextValue, setModalTextValue] = useState('');
    const [status, setStatus] = useState('');

    const urlCategory:string = new URLSearchParams(location.search).get("category") || '';
    const range:string = new URLSearchParams(location.search).get("range") || '';
    const urlStartDate:any = new URLSearchParams(location.search).get("start_date") || '';
    const urlEndDate:any = new URLSearchParams(location.search).get("end_date") || '';
    const urlSearch:any = new URLSearchParams(location.search).get("q") || '';
    const urlStatus:any = new URLSearchParams(location.search).get("status") || '';
    const urlPageNumber: any = new URLSearchParams(location.search).get("page") || 1; 

    useEffect( () => {
        setPage(urlPageNumber);
        setCategory(urlCategory);
        fetchDeletedTips();
    }, [page, location, category]);

    const fetchDeletedTips = async () => {
        try {
            const response = await Content.getDeletedTips(page, category);
            // console.log("res : ",response?.data);
            setTotal(response?.total);
            setDeletedTipsList(response?.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=> {
        Category.getCategories().then(res => {
            setCategories(res);
        }).catch(e => {
            console.log(e);
        });

    }, []);

    const handleCategory = (category: string) => {
        setPage(1);
        setCategory(category);
        
        if(category) {
            history.push(`${location.pathname}?category=${category}${startDate ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${searchKey ? `&q=${searchKey}` : ""}`);
        }
    }

    const onSelectFilter = (filter: string) => {
        setFilter(filter);

        const dates = {
            startDate,
            endDate
        }

        if (filter !== 'custom') {
            const getDate = convertToDate(filter);
            dates.startDate = getDate.startDate;
            dates.endDate = getDate.endDate;
            setStartDate(dates.startDate);
            setEndDate(dates.endDate);
        }
        
        if(filter === "custom") {
            filter = '';
        }

        if(filter) {
            history.push(`${location.pathname}${filter ? `?start_date=${dates.startDate}` : ""}${dates.endDate ? `&end_date=${dates.endDate}` : ""} ${searchKey ? `&q=${searchKey}` : ""}${category ? `&category=${category}` : ""}`);
        }

        if (filter === 'clear') {
            setFilter('');

            if (category) {
                history.push(`${location.pathname}?category=${category}${searchKey ? `&q=${searchKey}` : ""}`);
            } if (searchKey) {
                history.push(`${location.pathname}?q=${searchKey}${category ? `&category=${category}` : ""}`);
            } else {
                history.push(`${location.pathname}`);
            }
        }
    }

    const convertToDate = (range: string) => {
        const duration = range.split("-");
        let value = parseInt(duration[0]);
        let unit = duration[1];
        let days = 0;
        let totalDays;
        let startDate: any, endDate: any;

        switch (unit) {
            case 'day':
                days = 1;
                break;
            case 'week':
                days = 7;
                break;
            case 'month':
                days = 30;
                break;
            case 'year':
                days = 365;
                break;
            default:
                days = 1;
                break;
        }

        totalDays = days * value;

        startDate = new Date();
        endDate = new Date();

        startDate.setDate(startDate.getDate() - totalDays);

        startDate = moment(startDate).format("YYYY-MM-DD");
        endDate = moment(endDate).format("YYYY-MM-DD");

        return {
            startDate: startDate,
            endDate: endDate
        };
    }

    const onCalendarChange = (range:Date []) => {
        let convertedStartDate:any, convertedEndDate:any;
        
        convertedStartDate = moment(range[0]).format("YYYY-MM-DD");
        convertedEndDate = moment(range[1]).format("YYYY-MM-DD");

        setStartDate(convertedStartDate);
        setEndDate(convertedEndDate);

        if(filter) {
            history.push(`${location.pathname}${filter ? `?start_date=${convertedStartDate}` : ""}${convertedEndDate ? `&end_date=${convertedEndDate}` : ""} ${searchKey ? `&q=${searchKey}` : ""}${category ? `&category=${category}` : ""}`);
        }

    }

    const getInput = (searchKey:string) => {
        setPage(1);
        setSearchKey(searchKey);

        if(searchKey) {
            history.push(`${location.pathname}${searchKey ? `?q=${searchKey}` : ""}${filter ? `&start_date=${startDate}${endDate ? `&end_date=${endDate}` : ""}` : ""}${category ? `&category=${category}` : ""}`);
        } else {
            if(category) {
                history.push(`${location.pathname}?category=${category}${startDate ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${searchKey ? `&q=${searchKey}` : ""}`);
            } if (filter) {
                history.push(`${location.pathname}${filter ? `?start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${category ? `&category=${category}` : ""}`);
            }
        }
    }

    const onPageChange = (page:number) => {
        history.push(`${location.pathname}?page=${page}${searchKey ? `&q=${searchKey}` : ""}${filter ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${category ? `&category=${category}` : ""}`);
        setPage(page);
    }

    const showRestoreModal = () => {
        setRestoreModalVisible(true);
    }

    const showTrashModal = () => {
        setTrashModalVisible(true);
    }

    const handleRestoreModalCancel = () => {
        setRestoreModalVisible(false);
        setModalTextValue("");
    }

    const handleTrashModalCancel = () => {
        setTrashModalVisible(false);
        setModalTextValue("");
    }

    const restoreSuccess = (title: string) => {
        message.success(`${title} has been restored!`);
      };

    const trashSuccess = (title: string) => {
        message.success(`${title} has been trashed!`);
      };

    let previousLength = 0;
    
    const handleInput = (event:any) => {
        const bullet = "\u2022";
        const newLength = event.target.value.length;
        const characterCode = event.target.value.substr(-1).charCodeAt(0);

        if (newLength > previousLength) {
            if (characterCode === 10) {
                event.target.value = `${event.target.value}${bullet}  `;
            } else if (newLength === 1) {
                event.target.value = `${bullet} ${event.target.value}`
            }
        }

        previousLength = newLength;
        setModalTextValue(event.target.value)
    }

    const restoreTips = (slug: string) => {
        console.log("restore slug : ", slug);
        setSlug(slug);
        showRestoreModal();
    }

    const handleTipsRestore = () => {
        Content.restoreTips(slug).then(res => {
            let title: string = "";
            let updatedList: any = [];

            deletedTipsList?.map((deletedTips: any, index) => {
                if (deletedTips.tipsSlug !== slug) {
                    updatedList.push(deletedTips);
                } else {
                    title = deletedTips.tipsTitle;
                }
            });

            restoreSuccess(title);
            setDeletedTipsList(updatedList);
            setTotal(total-1);
        }).catch(e => {
            console.log(e)
        })

        setRestoreModalVisible(false);
    }

    const trashTips = (slug: string) => {
        console.log("trash slug : ",slug);
        setTrashTipsSlug(slug);
        showTrashModal();
    }

    const handleTipsTrash = () => {
        Content.trashTips(trashTipsSlug).then(res => {
            let title: string = "";
            let updatedTipsList: any = [];
            
            deletedTipsList?.map((deletedTips: any, index) => {
                if (deletedTips.tipsSlug !== trashTipsSlug) {
                    updatedTipsList.push(deletedTips);
                } else {
                    title = deletedTips.tipsTitle;
                }
            })

            trashSuccess(title);
            setDeletedTipsList(updatedTipsList);
            setTotal(total-1);
        }).catch(e => {
            console.log(e);
        })

        setTrashModalVisible(false);
    }



    const restoreModalFooter = (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <Button type='primary' size='large' onClick={handleTipsRestore}>
                Restore
            </Button>
        </div>
    )

    const trashModalFooter = (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <Button type='primary' danger size='large' onClick={handleTipsTrash}>
                Trash
            </Button>
        </div>
    )

    return (
        <Layout>
            <div className="trashed-tips">
                    <PageHeader title="List of Deleted Tips"/>
                <div className="trashed-tips-header">
                    <div className="trashed-tips-types">
                        <Button className="trashed-tips-types-btn" type="primary">
                            Free
                        </Button>
                        <Button className="trashed-tips-types-btn">
                            Premium
                        </Button>
                    </div>
                    <div className="trashed-tips-filter">
                        <Select onSelect={handleCategory} className='tips-select' value={category || "All category"} style={{ width: 182 }}
                            suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            }
                        >
                            {
                                categories.map((category: any, index) => {
                                    return (
                                        <Select.Option key={category.uuid} value={category.slug}>{category.name}</Select.Option>
                                    );
                                })
                            }
                        </Select>
                        <Select className='tips-select' value={filter || "Filter"} style={{ width: 182 }}
                            suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            }
                            onSelect={onSelectFilter}
                        >
                            <Select.Option value="7-day">Last 7 days</Select.Option>
                            <Select.Option value="1-month">Last 1 month</Select.Option>
                            <Select.Option value="6-month">Last 6 months</Select.Option>
                            <Select.Option value="1-year">Last 1 year</Select.Option>
                            <Select.Option value="custom">Custom Date range</Select.Option>
                            <Select.Option value="clear">Clear Filters</Select.Option>
                        </Select>
                    </div>
                    {filter === "custom" && <RangePicker onCalendarChange={onCalendarChange} />}
                </div>
                <div className="trashed-tips-filter-search">
                    <div className="trashed-tips-filter-search-left">
                        <Select value={status || "Restore"} className="select-option-width"  suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.5" d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                }>
                            <Select.Option value="restore">Restore</Select.Option>
                            <Select.Option value="delete-permanently">Delete Permanently</Select.Option>
                        </Select>
                        <Button size="small" className="trashed-tips-apply-btn">Apply</Button>
                    </div>
                    <div className="trashed-tips-filter-search-right">
                        <span>Tips Count: {total}</span>
                        <Input.Search onSearch={getInput} allowClear placeholder="input search text" style={{ width: 230 }}
                        />
                    </div>
                </div>
                <div className="trashed-tips-list">
                    <div className='trashed-tips-list-header'>
                        <div className='trashed-tips-list-header-item'>Title</div>
                        <div className='trashed-tips-list-header-item'>Author</div>
                        <div className='trashed-tips-list-header-item'>Publish Date</div>
                        <div className='trashed-tips-list-header-item'>Deleted By</div>
                        <div className='trashed-tips-list-header-item'>Action</div>
                    </div>
                    <div className="trashed-tips-list-body">
                        {
                            deletedTipsList?.map((deletedTips: any, index) => {
                                return (
                                    <div className="trashed-tips-list-body-item">
                                        <div className="trashed-tips-list-item-title">
                                            <Checkbox className="trashed-tips-list-item-title-checkbox" />
                                            <span>{deletedTips.tipsTitle}</span>
                                        </div>
                                        <div className="trashed-tips-list-item-author">
                                            <Avatar className='trashed-tips-list-item-author-avatar' src={deletedTips.authorAvatar} size={36}  icon={<UserOutlined />}/>
                                            <span>{deletedTips.authorName.length >  20 ? deletedTips.authorName.slice(0, 20)+'..' : deletedTips.authorName}</span>
                                        </div>
                                        <div className="trashed-tips-list-item-publish-date">{"1 Jan, 2022"}</div>
                                        <div className="trashed-tips-list-item-deleted-by">
                                            <Avatar className='trashed-tips-list-item-author-avatar' src={deletedTips.adminAvatar} size={36}  icon={<UserOutlined />}/>
                                            <span>{deletedTips.adminName.length >  20 ? deletedTips.adminName.slice(0, 20)+'..' : deletedTips.adminName}</span>
                                        </div>
                                        <div className="trashed-tips-list-item-action">
                                            <div className="trashed-tips-list-item-action-restore">
                                                <Button className="action-btn" onClick={ () => restoreTips(deletedTips.tipsSlug)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                        <path d="M8.77001 1.0002C6.12001 1.0702 3.77001 2.2802 2.17001 4.1602L0.850009 2.8502C0.779513 2.7811 0.69014 2.73441 0.593152 2.71602C0.496164 2.69763 0.395903 2.70836 0.305003 2.74686C0.214104 2.78536 0.136634 2.8499 0.0823584 2.93236C0.0280824 3.01481 -0.000571234 3.11149 8.63001e-06 3.2102V7.5002C8.63001e-06 7.7802 0.220009 8.0002 0.500009 8.0002H4.79001C5.24001 8.0002 5.46001 7.4602 5.14001 7.1502L3.59001 5.5902C4.88001 4.0202 6.82001 3.0002 9.00001 3.0002C13.32 3.0002 16.74 6.9402 15.86 11.4102C15.32 14.1802 13.05 16.3902 10.28 16.8802C6.48001 17.5602 3.10001 15.1402 2.23001 11.7202C2.11001 11.3002 1.71001 11.0002 1.27001 11.0002C0.620009 11.0002 0.130009 11.6102 0.290009 12.2302C1.28001 16.1202 4.80001 19.0002 9.00001 19.0002C14.06 19.0002 18.14 14.8302 18 9.7402C17.86 4.8602 13.65 0.880203 8.77001 1.0002Z" fill="#4B607C"/>
                                                    </svg>
                                                </Button>
                                            </div>
                                            <div className="trashed-tips-list-item-action-view">
                                                <a href="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z" fill="#3161F1"/>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="trashed-tips-list-item-action-delete">
                                                <Button className="action-btn" onClick={ () => trashTips(deletedTips.tipsSlug)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <path d="M5.73438 1.95312H5.5625C5.65703 1.95312 5.73438 1.87578 5.73438 1.78125V1.95312H12.2656V1.78125C12.2656 1.87578 12.343 1.95312 12.4375 1.95312H12.2656V3.5H13.8125V1.78125C13.8125 1.02285 13.1959 0.40625 12.4375 0.40625H5.5625C4.8041 0.40625 4.1875 1.02285 4.1875 1.78125V3.5H5.73438V1.95312ZM16.5625 3.5H1.4375C1.05723 3.5 0.75 3.80723 0.75 4.1875V4.875C0.75 4.96953 0.827344 5.04688 0.921875 5.04688H2.21953L2.7502 16.2832C2.78457 17.0158 3.39043 17.5938 4.12305 17.5938H13.877C14.6117 17.5938 15.2154 17.018 15.2498 16.2832L15.7805 5.04688H17.0781C17.1727 5.04688 17.25 4.96953 17.25 4.875V4.1875C17.25 3.80723 16.9428 3.5 16.5625 3.5ZM13.7115 16.0469H4.28848L3.76855 5.04688H14.2314L13.7115 16.0469Z" fill="#FC3273"/>
                                                    </svg>
                                                </Button>
                                            </div>
                                        </div>
                                        <Modal title="Restore Tips" visible={restoreModalVisible} footer={restoreModalFooter} onCancel={handleRestoreModalCancel}>
                                            <TextArea onInput = {handleInput} value={modalTextValue} showCount rows={4} placeholder="Terms and Conditions" maxLength={250}/>
                                        </Modal>
                                        <Modal title="Trash Tips" visible={trashModalVisible} footer={trashModalFooter} onCancel={handleTrashModalCancel}>
                                            <TextArea onInput = {handleInput} value={modalTextValue} showCount rows={4} placeholder="Terms and Conditions" maxLength={250}/>
                                        </Modal>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={styles.PaginationContainer}>
                    <Pagination size="small" total={total}
                        defaultPageSize={15}
                        current={page}
                        onChange={onPageChange}/>
                </div>
            </div>
        </Layout>
    )    
}

export default TrashedTips;