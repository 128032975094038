import {
    SERVER_TIPS_USER_ENDPOINT,
    SERVER_TIPS_USER_STATUS_UPDATE_ENDPOINT,
    SERVER_TIPS_USER_DETAILS_ENDPOINT,
    SERVER_ACCOUNT_AVATAR_CHANGE_ENDPOINT,
    SERVER_TIPS_USER_PROFILE_ENDPOINT,
    SERVER_TIPS_ADMIN_LOGOUT_ENDPOINT,
} from '../../config/endpoints';
import { axioService, GET, POST } from '../../services/axiosService';
import { getAuthToken, getAuthUser, getAuthUsername } from '../../services/authentication';
import { mapUserFromServerToClient } from './mapper';

class User {
    getUsers = async (page: number, q?: string) => {
        const token = getAuthToken();

        const response = await axioService(
            GET,
            SERVER_TIPS_USER_ENDPOINT,
            { page, q },
            token
        );

        return response?.data;
    }

    updateUserStatus = async (username: string, status: number) => {
        const token = getAuthToken();

        const response = await axioService(
            POST,
            SERVER_TIPS_USER_STATUS_UPDATE_ENDPOINT.replace(':username', username),
            { status },
            token
        );

        return response?.data;
    }

    getUser = async (username: string) => {
        const response = await axioService(
            GET,
            SERVER_TIPS_USER_DETAILS_ENDPOINT.replace(':username', username),
            {},
            getAuthToken()
        )
        return mapUserFromServerToClient(response?.data.data);
    }

    updateProfile = async (profileFormObj: any): Promise<void> => {
        await axioService(
          POST,
          SERVER_TIPS_USER_PROFILE_ENDPOINT.replace(':username', profileFormObj.username),
          {
            ...profileFormObj
          },
          getAuthToken()
        );
      };

    changeAvatar = async (avatar: any): Promise<string> => {
        const formData = new FormData();
        formData.append('avatar', avatar);

        const response = await axioService(
            POST,
            SERVER_ACCOUNT_AVATAR_CHANGE_ENDPOINT,
            formData,
            getAuthToken()
        );
        return response?.data?.avatar || '';
    };

    removeAvatar = async (): Promise<string> => {
        const response = await axioService(
            POST,
            SERVER_ACCOUNT_AVATAR_CHANGE_ENDPOINT,
            { avatar: null },
            getAuthToken()
        );
        return response?.data?.avatar || '';
    };

    logout = async (): Promise<string> => {
        const response = await axioService(
            POST,
            SERVER_TIPS_ADMIN_LOGOUT_ENDPOINT,
            {},
            getAuthToken()
        );
        return response?.data || '';
    };
}

export default new User();