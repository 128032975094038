import { withRouter, Route, Redirect } from "react-router-dom";
import { Avatar, Button, Input, Form, Modal, message } from 'antd';
import { SettingOutlined, UserOutlined } from '@ant-design/icons'
import Layout from "../../components/layout/Layout"
import AdminProfile from '../../api/admin-profile/request'
import User from '../../api/users/request';
import './style.scss';
import { useEffect, useState, useRef } from "react";
import useForm from 'antd/lib/form/hooks/useForm';
import {useHistory} from "react-router-dom";
import { destroyState } from "./../../../src/services/cookieStorageSync";

function MyProfile() {
    const history = useHistory();
    const [userName, setUserName] = useState<string | undefined>('');
    const [userAvatar, setUserAvatar] = useState<string | undefined>('');
    const [name, setName] = useState<string | undefined>('');
    const [email, setEmail] = useState<string | undefined>('');
    const [randomPassword, setRandomPassword] = useState('');
    const [password, setPassword] = useState('');
    const [btnDisplay, setBtnDisplay] = useState('');
    const [form] = useForm();

    useEffect(() => {
        fetchAdmin();
    }, []);

    const fetchAdmin = async () => {
        try {
            const res = await AdminProfile.getAdmin();
            setUserName(res?.username);
            setUserAvatar(res?.avatar);
            setName(res?.name);
            setEmail(res?.email);
            generatePassword();
            form.resetFields();
        } catch (error) {
            console.log(error);
        }
    }

    const inputRef = useRef<any>();
    const openFile = () => {
        inputRef?.current?.click();
    };

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files) {
            if (event.target.files[0]) {
                try {
                    console.log("file url: ", event.target.files[0]);
                    const url = await User.changeAvatar(
                        event.target.files[0]
                    );
                    setUserAvatar(url || '');
                    message.success('Saved');
                    window.location.reload();
                } catch (exception) {
                    message.error('Failed');
                    console.error(exception);
                }
            }
        }
    };

    const removeAvatar = async () => {
        Modal.confirm({
            title: 'Do you want to remove your current profile pic?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                try {
                    User.removeAvatar().then(
                        () => {
                            setUserAvatar('');
                            message.success('Removed successfully');
                            window.location.reload();
                        }    
                    );
                } catch (exception) {
                    message.error('Failed');
                    console.error(exception);
                }
            },
        });
    };

    const generatePassword = () => {
        let wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$';
        let randomPassword = Array.from(
            crypto.getRandomValues(new Uint32Array(35))
        ).map((x) => wishlist[x % wishlist.length]).join('');

        setRandomPassword(randomPassword);
    };

    const renderPasswordField = () => {
        setPassword(randomPassword);
        setBtnDisplay("none");
    }

    const signout = async () => {
        destroyState();
        window.location.href = 'https://devaccounts.virtunus.com/logout?app=staging.admin';
    }

    const onSubmit = async (values: any) => {
        try {
            if (password === randomPassword || email !== values.email) {
                const modifiedFormData = {
                    name: values.name,
                    username: userName,
                    email: values.email,
                    password: values.password,
                };
                await User.updateProfile(modifiedFormData);
                message.success('Saved');
                signout();
            } else {
                const modifiedFormData = {
                    name: values.name,
                    username: userName,
                };
                await User.updateProfile(modifiedFormData);
                message.success('Saved');
            }
        } catch (Exception) {
            console.error(Exception);
            message.error('Failed');
        }
    }

    return (
        <Layout>
            <Form
                form={form}
                initialValues={{
                    email: email,
                    name: name,
                    username: userName,
                    userAvatar: userAvatar,
                    password: randomPassword,
                }}
                onFinish={onSubmit}
            >
                <div className="my-profile-header">
                    <h3 className="my-profile-header-text">Personal Information</h3>
                </div>
                <Form.Item
                >
                    <div className="my-profile-profile-picture">
                        <h4 className="my-profile-profile-picture-label">Profile Picture</h4>
                        <Avatar className="my-profile-profile-picture-avatar" size={76} src={userAvatar} icon={<UserOutlined />} />
                        <input
                            name="avatar"
                            type="file"
                            style={{display: "none"}}
                            ref={inputRef}
                            accept="image/*"
                            onChange={onFileChange}
                        />
                        <Button className="my-profile-profile-picture-btn-change" type="link" onClick={openFile}>
                            Change
                        </Button>
                        <Button className="my-profile-profile-picture-btn-remove" danger type="link" onClick={removeAvatar}>
                            Remove
                        </Button>
                    </div>
                </Form.Item>
                <Form.Item
                    label={<div>Name</div>}
                    name="name"
                    rules={[{ required: true, message: 'Please input your name!' }]}
                >
                    <Input className="my-profile-name-input" />
                </Form.Item>
                <Form.Item
                    label={<div>Email</div>}
                    name="email"
                    rules={[
                        { required: true, message: 'Please input your email!' },
                        { type: 'email', message: 'Please provide a valid email!' },
                    ]}
                >
                    <Input className="my-profile-email-input" />
                </Form.Item>
                <Form.Item
                    label={<div>Username</div>}
                    name="username"
                >
                    <Input className="my-profile-username-input" placeholder={userName} disabled />
                    <div className="my-profile-username-info">
                        Username cannot be changed
                    </div>
                </Form.Item>
                <div>
                    <h3 className="my-profile-security-label">Security</h3>
                </div>
                <Form.Item
                    label={<div>New Password</div>}
                    style={{ display: btnDisplay }}
                >
                    <Button className="my-profile-password-btn-generate" type="primary" onClick={renderPasswordField}>Generate Password</Button>
                </Form.Item>
                <Form.Item
                    name="password"
                    label={<div>New Password</div>}
                    style={btnDisplay === "" ? { display: "none" } : { display: "" }}
                    rules={[
                        { required: true, message: 'Please provide a Strong password!' },
                        { min: 8, message: 'Password must contain at least 8 characters!'},
                        { max: 100, message: 'Password can not excede 100 characters!'},

                    ]}
                >
                    <Input className="my-profile-password-input" />
                </Form.Item>
                <Form.Item
                    name="update"
                >
                    <Button className="my-profile-update-btn-primary" htmlType="submit" type="primary">Update Profile</Button>
                </Form.Item>
            </Form>
        </Layout>
    )
}

export default withRouter(MyProfile);