
import { ServerCategoryObj, CategoryObj } from "./dataTypesd"
const categoryMapper = (category: ServerCategoryObj) : CategoryObj=> {

    return { 
        uuid: category.uuid,
        name: category.name, 
        slug : category.slug,
        image: category.image,
     }
}

export {categoryMapper};