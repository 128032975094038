import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout/Layout";
import "./style.scss";
import { Tabs, Card, Avatar, Row, Col, Divider, Switch, Modal,Button } from 'antd'
import { UserOutlined } from "@ant-design/icons";
import { withRouter, useParams, useLocation, useHistory, Link } from "react-router-dom";
import User from '../../../api/users/request';
import CreatedTips from '../../../components/createdTips/CreatedTips';
import EnrolledTips from '../../../components/enrolledTips/EnrolledTips';
import UnenrolledTips from "../../../components/unenrolledTips/UnenrolledTips";
import UserOverview from '../../../components/userOverview/UserOverview';
import {UserInterface, WorkPlaceDetails} from '../../../interfaces/user.interface';

const { TabPane } = Tabs;
interface paramsInterface {
    userName: string;
}
const UserDetails: React.FC = (props) => {

    const history = useHistory();
    const location = useLocation();
    const [user, setUser] = useState<UserInterface | null>(null);
    const params:paramsInterface = useParams();
    const [username, setUsername] = useState("");
    const [userStatus, setUserStatus] = useState(false);
    const [userPreviousStatus, setUserPreviousStatus] = useState(false);
    const [userUpdateStatus, setUserUpdateStatus] = useState(0);
    const [enableModalVisible, setEnableModalVisible] = useState(false);
    const [disableModalVisible, setDisableModalVisible] = useState(false);
    const [userLoader, setUserLoader] = useState(false);

    useEffect ( () => {
        fetchUser();
    }, [userLoader]);
    
    const fetchUser = async () => {
        try {
            const { userName } = params
            const response = await User.getUser(userName)
            setUser(response);  
            setUsername(userName);
            setUserPreviousStatus(response.is_active);  
        } catch (error) {
            console.error(error)
        }
    }

    const showEnableModal = () => {
        setEnableModalVisible(true);
    }

    const showDisableModal = () => {
        setDisableModalVisible(true);
    }

    const handleEnableModalCancel = () => {
        setEnableModalVisible(false);
    }

    const handleDisableModalCancel = () => {
        setDisableModalVisible(false);
    }

    const userActivationStatusUpdate = (checked: boolean) => {
        if (checked === true) {
            showEnableModal();
            setUserUpdateStatus(1);
        }
        if (checked ===  false) {
            showDisableModal();
            setUserUpdateStatus(0);
        }
    }

    const handleEnable = () => {
        User.updateUserStatus(username, userUpdateStatus).then(res => {

            if (userPreviousStatus !== res.data.is_active) {
                setUserLoader(!userLoader);
            }
        }).catch(e => {
            console.log(e);
        })

        setEnableModalVisible(false);
    }

    const handleDisable = () => {
        User.updateUserStatus(username, userUpdateStatus).then(res => {

            if (userPreviousStatus !== res.data.is_active) {
                setUserLoader(!userLoader);
            }
        }).catch(e => {
            console.log(e);
        })

        setDisableModalVisible(false);
    }

    const getCurrentTab= (clickedTab: string) => {
        history.push(`${location.pathname}?tab=${clickedTab}`);
    }

    const callback = (key: string) => {
        localStorage.setItem("currentTab", key);
    }
   
    const redirectToAuthorDetails = (authorUrl: string) => {
        window.open(authorUrl, '_blank');
    }

    const enableFooter = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" size='large' onClick={handleEnable}>
                Enable
            </Button>
        </div>
    )

    const disableFooter = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" danger size="large" onClick={handleDisable}>
                Disable
            </Button>
        </div>
    )

    return (
        !user ? <div>Loading</div> : <Layout>
        <div className="user">
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                <path d="M5.25561 1.50043C5.25596 1.63673 5.19493 1.76882 5.08312 1.87376L1.72312 5.00043L4.96312 8.13293C5.02541 8.1926 5.07194 8.26125 5.10001 8.33495C5.12809 8.40865 5.13716 8.48594 5.1267 8.56237C5.11625 8.6388 5.08647 8.71288 5.03909 8.78033C4.99171 8.84779 4.92766 8.90729 4.85062 8.95543C4.7739 9.00389 4.68563 9.04007 4.59087 9.06191C4.49612 9.08374 4.39675 9.0908 4.29848 9.08266C4.20021 9.07453 4.10497 9.05138 4.01824 9.01452C3.93152 8.97767 3.85501 8.92785 3.79312 8.86793L0.170616 5.36793C0.0603045 5.26355 -1.72805e-07 5.13263 -1.78711e-07 4.99751C-1.84617e-07 4.8624 0.0603045 4.73147 0.170616 4.6271L3.92062 1.1271C3.98358 1.06803 4.0609 1.0192 4.14816 0.983415C4.23542 0.947627 4.3309 0.925578 4.42914 0.918534C4.52737 0.91149 4.62642 0.919588 4.72063 0.942364C4.81483 0.96514 4.90233 1.00215 4.97812 1.05126C5.06415 1.10555 5.13366 1.17398 5.18164 1.25164C5.22962 1.3293 5.25488 1.41427 5.25561 1.50043Z" fill="#3161F1" />
            </svg>
            <Link to={{
                pathname: '/users',
            }}>
            {/* <span className="user-span">Back to List</span> */}
            <a className="user-span" href="">Back to List</a>
            </Link>
        </div>
        <div className="user-info">
            <Card style={{ borderRadius: "5px", overflow: "hidden"}}>
                <Row>
                    <Col className="user-info-grid" span={11}>
                        <Row style={{margin: "0px"}}>
                            <Col span={12}>
                                <Avatar className="user-avatar" src={user.avatar} size={164} icon={<UserOutlined />}/>
                            </Col>
                            <Col span={12} className="user-info-column">
                                <span className="user-info-grid-user-name">{ user.name }</span>
                                <Row gutter={20}>
                                    <Col>
                                        <Row className="user-info-grid-bold">
                                            {user.counts.tips}
                                        </Row>
                                        <Row className="user-info-grid-muted">
                                            {"Tips"}
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="user-info-grid-bold">
                                            {user.counts.followings}
                                        </Row>
                                        <Row className="user-info-grid-muted">
                                            {"Following"}
                                        </Row>
                                    </Col>
                                    <Col>
                                    <Row className="user-info-grid-bold">
                                        {user.counts.followers}
                                    </Row>
                                    <Row className="user-info-grid-muted">
                                        {"Followers"}
                                    </Row>
                                    </Col>
                                </Row>
                                <div className="user-info-grid-email-address">
                                        <svg  className="user-info-grid-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                            <path d="M17.3334 1.99967C17.3334 1.08301 16.5834 0.333008 15.6667 0.333008H2.33335C1.41669 0.333008 0.666687 1.08301 0.666687 1.99967V11.9997C0.666687 12.9163 1.41669 13.6663 2.33335 13.6663H15.6667C16.5834 13.6663 17.3334 12.9163 17.3334 11.9997V1.99967ZM15.6667 1.99967L9.00002 6.16634L2.33335 1.99967H15.6667ZM15.6667 11.9997H2.33335V3.66634L9.00002 7.83301L15.6667 3.66634V11.9997Z" fill="#C2CFE0" />
                                        </svg>
                                    <span>
                                        {user.email}
                                    </span>
                                </div>
                                <div className="user-info-grid-email-address">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10.0001 1.66699C6.77508 1.66699 4.16675 4.27533 4.16675 7.50033C4.16675 11.8753 10.0001 18.3337 10.0001 18.3337C10.0001 18.3337 15.8334 11.8753 15.8334 7.50033C15.8334 4.27533 13.2251 1.66699 10.0001 1.66699ZM5.83341 7.50033C5.83341 5.20033 7.70008 3.33366 10.0001 3.33366C12.3001 3.33366 14.1667 5.20033 14.1667 7.50033C14.1667 9.90033 11.7667 13.492 10.0001 15.7337C8.26675 13.5087 5.83341 9.87533 5.83341 7.50033Z" fill="#C2CFE0" />
                                        <path d="M10.0001 9.58366C11.1507 9.58366 12.0834 8.65092 12.0834 7.50033C12.0834 6.34973 11.1507 5.41699 10.0001 5.41699C8.84949 5.41699 7.91675 6.34973 7.91675 7.50033C7.91675 8.65092 8.84949 9.58366 10.0001 9.58366Z" fill="#C2CFE0" />
                                    </svg>
                                    <span style={{}}>
                                        {user.location}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={1}>
                        <Divider className="user-info-divider" type="vertical"/>
                    </Col>
                    <Col className="user-info-grid" span={10}>
                        <Row style={{marginTop: "10px", marginBottom: "10px"}}>
                            <Col span={24}>
                                <span className="user-info-grid-bold">{"Work"}</span>
                            </Col>
                            <Col span={24}>
                                <div>{user.workplace}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <span className="user-info-grid-bold">{"Social Media"}</span>
                            </Col>
                            <Col span={24}>
                                <div className="user-info-grid-social-logo">
                                    {user?.social_info?.facebook && <div className="user-info-grid-social-logo-item">
                                        <a href={user.social_info.facebook} target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                            <circle cx="14.3378" cy="14.662" r="14.3378" fill="#4267B2"/>
                                            <path d="M17.4599 15.825L17.852 13.2703H15.4007V11.6125C15.4007 10.9136 15.7431 10.2324 16.841 10.2324H17.9553V8.05736C17.9553 8.05736 16.9441 7.88477 15.9772 7.88477C13.9585 7.88477 12.639 9.10833 12.639 11.3233V13.2703H10.395V15.825H12.639V22.0008H15.4007V15.825H17.4599Z" fill="white"/>
                                        </svg>
                                        </a>
                                    </div>}
                                    {user?.social_info?.twitter && <div className="user-info-grid-social-logo-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
                                            <circle cx="15.2489" cy="14.3378" r="14.3378" fill="#66B7FB"/>
                                            <path d="M21.8433 11.4176C21.8541 11.5726 21.8541 11.7277 21.8541 11.8827C21.8541 16.6116 18.3461 22.0604 11.9347 22.0604C9.95946 22.0604 8.12455 21.4734 6.58105 20.4546C6.8617 20.4878 7.13151 20.4988 7.42296 20.4988C9.05277 20.4988 10.5531 19.934 11.7512 18.9705C10.2185 18.9373 8.93406 17.9074 8.49151 16.4898C8.7074 16.523 8.92326 16.5452 9.14995 16.5452C9.46295 16.5452 9.77599 16.5008 10.0674 16.4234C8.46995 16.0911 7.27182 14.6514 7.27182 12.9127V12.8684C7.73593 13.1342 8.27566 13.3003 8.84768 13.3224C7.90863 12.6801 7.29341 11.5837 7.29341 10.3433C7.29341 9.67885 7.46608 9.06974 7.76832 8.53816C9.48452 10.7088 12.0642 12.1263 14.9569 12.2814C14.9029 12.0156 14.8705 11.7388 14.8705 11.4619C14.8705 9.49058 16.4248 7.88477 18.3569 7.88477C19.3607 7.88477 20.2673 8.31668 20.9042 9.01438C21.6921 8.85935 22.4476 8.56031 23.1169 8.15056C22.8578 8.98118 22.3073 9.67889 21.5842 10.1218C22.2858 10.0444 22.9658 9.84497 23.5918 9.56813C23.1169 10.2769 22.5233 10.9081 21.8433 11.4176Z" fill="white"/>
                                        </svg>
                                    </div>}
                                    {user?.social_info?.youtube ? <div className="user-info-grid-social-logo-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                            <circle cx="14.4848" cy="14.3378" r="14.3378" fill="#FA3D3D"/>
                                            <path d="M21.2622 11.2531C21.0992 10.671 20.6188 10.2127 20.0089 10.0571C18.9035 9.77441 14.4706 9.77441 14.4706 9.77441C14.4706 9.77441 10.0378 9.77441 8.9323 10.0571C8.3224 10.2127 7.84206 10.671 7.67903 11.2531C7.38281 12.3081 7.38281 14.5092 7.38281 14.5092C7.38281 14.5092 7.38281 16.7103 7.67903 17.7652C7.84206 18.3473 8.3224 18.7866 8.9323 18.9421C10.0378 19.2248 14.4706 19.2248 14.4706 19.2248C14.4706 19.2248 18.9034 19.2248 20.0089 18.9421C20.6188 18.7866 21.0992 18.3473 21.2622 17.7652C21.5584 16.7103 21.5584 14.5092 21.5584 14.5092C21.5584 14.5092 21.5584 12.3081 21.2622 11.2531ZM13.0208 16.5076V12.5107L16.7258 14.5092L13.0208 16.5076Z" fill="white"/>
                                        </svg>
                                    </div> : null}
                                    {user?.social_info?.linkedin ? <div className="user-info-grid-social-logo-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                            <path d="M14.5001 0.580078C6.81218 0.580078 0.580078 6.81218 0.580078 14.5001C0.580078 22.188 6.81218 28.4201 14.5001 28.4201C22.188 28.4201 28.4201 22.188 28.4201 14.5001C28.4201 6.81218 22.188 0.580078 14.5001 0.580078ZM11.0926 20.2696H8.27378V11.1984H11.0926V20.2696ZM9.66578 10.0848C8.77548 10.0848 8.19983 9.45408 8.19983 8.67398C8.19983 7.87793 8.79288 7.26603 9.70203 7.26603C10.6112 7.26603 11.168 7.87793 11.1854 8.67398C11.1854 9.45408 10.6112 10.0848 9.66578 10.0848ZM21.3876 20.2696H18.5688V15.2425C18.5688 14.0723 18.1599 13.2777 17.1405 13.2777C16.3619 13.2777 15.8993 13.8157 15.6949 14.3333C15.6195 14.5175 15.6006 14.7785 15.6006 15.038V20.2682H12.7804V14.0912C12.7804 12.9587 12.7441 12.0119 12.7064 11.197H15.1555L15.2845 12.457H15.3411C15.7123 11.8654 16.6214 10.9925 18.1425 10.9925C19.997 10.9925 21.3876 12.2352 21.3876 14.9061V20.2696Z" fill="#0A66C2"/>
                                        </svg>
                                    </div> : null}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2}>
                    <div className="user-info-action">
                        <div>
                            <svg onClick={ () => redirectToAuthorDetails(user.authorUrl) } className="user-info-action" style={{marginRight: "15px"}} width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z" fill="#3161F1" />
                            </svg>
                        </div>
                        <div>
                            <Switch defaultChecked={user.is_active} onChange={userActivationStatusUpdate} style={{marginTop: "5px"}}/>
                            <Modal title="Enable User" visible={enableModalVisible} footer={enableFooter} maskStyle={{ opacity: '0.3' }} onCancel={handleEnableModalCancel}>
                                <p>This user will be enable in system. Once the user is enabled it will be available in the following places</p>
                                <ul>
                                    <li>User’s personal profile</li>
                                    <li>Content feed</li>
                                </ul>
                            </Modal>
                            <Modal title="Disable User" visible={disableModalVisible} footer={disableFooter} maskStyle={{ opacity: '0.3' }} onCancel={handleDisableModalCancel}>
                                <p>This user will be disabled from the system. Once the user is disabled it won’t be available in the following places</p>
                                <ul>
                                    <li>User’s personal profile</li>
                                    <li>Content feed</li>
                                </ul>
                            </Modal>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Card>
        </div>
        <Card style={{ borderRadius: "5px", overflow: "hidden"}} className="user-tips-list">
        <Tabs onTabClick={getCurrentTab} className="user-tips-tabs" defaultActiveKey= {`${localStorage.getItem("currentTab")}`}  onChange={callback} >
            <TabPane tab="Created Tips" key="tips">
                <CreatedTips userName={user.username} />
            </TabPane>
            <TabPane tab="Enrolled" key="enrolled-tips">
                <EnrolledTips userName={user.username}/>
            </TabPane>
            <TabPane tab="Unenrolled" key="unenrolled-tips">
                <UnenrolledTips username={user.username}/>
            </TabPane>
            <TabPane tab="Overview" key="overview">
                <UserOverview userName={user.username}/>
            </TabPane>
        </Tabs>
        </Card>
    </Layout>
        
    )
}

export default UserDetails;