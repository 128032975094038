import {
    SERVER_TIPS_USER_ENROLLED_TIPS_ENDPOINT
} from '../../config/endpoints';
import { axioService, GET } from '../../services/axiosService';
import { getAuthToken } from '../../services/authentication';
import { mapTipsListFromServerToClient } from '../content/mapper';

class AuthorEnrolledTips {
    getEnrolledTips = async (page: number, username: string, type: string) => {
        const token = getAuthToken();

        console.log(token);
        const response = await axioService(
            GET,
            SERVER_TIPS_USER_ENROLLED_TIPS_ENDPOINT.replace(':username', username),
            {username, page, type},
            token
        );

        if (response) {
            return {
                data: mapTipsListFromServerToClient(response?.data.data),
                total: response?.data.meta.total,
                from: response?.data.meta.from,
            };
        }
    }
}

export default new AuthorEnrolledTips();