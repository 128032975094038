import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import Category from '../../api/categories/request';
import AuthorOverview from '../../api/authorOverview/request';
import './UserOverview.scss';
import { Pie } from '@ant-design/plots';

function UserOverview({ userName }: { userName: string }) {
    const statsHeaders = ['Enrollment Statistics', 'TIPS Complete - Statistics (Progress = 100%)', 'TIPS Incomplete - Statistics (Progress < 100%)'];
    const [categories, setCategories] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState<number | undefined | null>(0);
    const [categoryColors, setCategoryColors] = useState<any>([]);
    const [enrolledCategories, setEnrolledCategories] = useState<any>();
    const [completedCategories, setCompletedCategories] = useState<any>();
    const [incompletedCategories, setIncompletedCategories] = useState<any>();
    const enrolledCategoryList: any = [];
    const completedCategoryList: any = [];
    const incompletedCategoryList: any = [];
    const configData1: any = [];
    let configData2: any = [];
    let configData3: any = [];
    const colorArray: any = [];
    const colorArray2: any = [];
    const colorArray3: any = [];

    useEffect(() => {
        Category.getCategories().then(res => {
            setCategories(res);
        }).catch(e => {
            console.log(e);
        });

        fetchOverviewData();
    }, []);
    
    const fetchOverviewData = async () => {
        try {
            const res = await AuthorOverview.getUserOverview(page, userName);
            setEnrolledCategories(res?.enrolledCategories);
            setCompletedCategories(res?.completedCategories);
            setIncompletedCategories(res?.incompletedCategories);
            setTotal(res?.total);
        } catch (error) {
            console.log(error);
        }
    };

    const categoriesColorPicker = (categories: any) => {
        categoryColors.length = 0;

        categories.forEach((category: any) => {
            let randomColor = "#000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); });

            categoryColors.push(
                {
                    name: category.name,
                    color: randomColor
                }
            );
        });
    }

    const dataMapper = (statsHeader: any) => {
        let percentage: number;

        switch (statsHeader) {
            case statsHeaders[0]:
                if (!enrolledCategoryList.length) {
                    configData1.push({
                        type: "No Data Available",
                        value: 0,
                    });
                
                    colorArray.push('#1111');
                
                    break;
                }

                enrolledCategoryList.map((category: any) => {
                    percentage = getPercentage(category, (total === 0 || !total ? 1 : total));
                    configData1.push({
                        type: `${category.name} -- ${percentage}%`,
                        value: percentage,
                    });
                });
                break;

            case statsHeaders[1]:
                if (!completedCategoryList.length) {
                    configData2.push({
                        type: "No Data Available",
                        value: 0,
                    });
                
                    colorArray2.push('#1111');
                
                    break;
                }

                let totalCompleted = 0;
                completedCategoryList.map((category: any) => {
                    totalCompleted+= category.value;
                });

                completedCategoryList.map((category: any) => {
                    percentage = getPercentage(category, totalCompleted);
                    configData2.push({
                        type: `${category.name} -- ${percentage}%`,
                        value: percentage,
                    });
                });
                break;

            case statsHeaders[2]:
                if (!incompletedCategoryList.length) {
                    configData3.push({
                        type: "No Data Available",
                        value: 0,
                    });
                
                    colorArray3.push('#1111');
                
                    break;
                }

                let totalIncompleted = 0;
                incompletedCategoryList.map((category: any) => {
                    totalIncompleted+= category.value;
                });

                incompletedCategoryList.map((category: any) => {
                    percentage = getPercentage(category, totalIncompleted);
                    configData3.push({
                        type: `${category.name} -- ${percentage}%`,
                        value: percentage,
                    });
                });
                break;
            default:
                percentage = 0;
        }
    }

    const getPercentage = (category: any, total: number) => {
        let fraction = (category.value / (total === 0 ? 1 : total)) * 100;
        return Math.round((fraction + Number.EPSILON) * 100) / 100;
    }

    const config1 = {
        appendPadding: 10,
        data: configData1,
        angleField: 'value',
        colorField: 'type',
        color: colorArray,
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            content: '',
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };

    const config2 = {
        appendPadding: 10,
        data: configData2,
        angleField: 'value',
        colorField: 'type',
        color: colorArray2,
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            content: '',
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };

    const config3 = {
        appendPadding: 10,
        data: configData3,
        angleField: 'value',
        colorField: 'type',
        color: colorArray3,
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            content: '',
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };

    const setColoredEnrolledCategories = (enrolledCategories: any) => {
        for (let key in enrolledCategories) {
            for (let j = 0; j < categoryColors.length; j++) {
                let colorObj = categoryColors[j];
                if (key === colorObj.name) {
                    enrolledCategoryList.push(
                        {
                            name: key,
                            value: enrolledCategories[key],
                            color: colorObj.color
                        }
                    );
                }
            }
        }

        for (let i = 0; i < enrolledCategoryList.length; i++) {
            colorArray.push(enrolledCategoryList[i].color);
        }
    }

    const setColoredCompletedCategories = (completedCategories: any) => {
        for (let key in completedCategories) {
            for (let j = 0; j < categoryColors.length; j++) {
                let colorObj = categoryColors[j];
                if (key === colorObj.name) {
                    completedCategoryList.push(
                        {
                            name: key,
                            value: completedCategories[key],
                            color: colorObj.color
                        }
                    );
                }
            }
        }

        for (let i = 0; i < completedCategoryList.length; i++) {
            colorArray2.push(completedCategoryList[i].color);
        }
    }

    const setColoredIncompletedCategories = (incompletedCategories: any) => {
        for (let key in incompletedCategories) {
            for (let j = 0; j < categoryColors.length; j++) {
                let colorObj = categoryColors[j];
                if (key === colorObj.name) {
                    incompletedCategoryList.push(
                        {
                            name: key,
                            value: incompletedCategories[key],
                            color: colorObj.color
                        }
                    );
                }
            }
        }
        for (let i = 0; i < incompletedCategoryList.length; i++) {
            colorArray3.push(incompletedCategoryList[i].color);
        }
    }

    categoriesColorPicker(categories);
    setColoredEnrolledCategories(enrolledCategories);
    setColoredCompletedCategories(completedCategories);
    setColoredIncompletedCategories(incompletedCategories);

    return (
        <div className='user-info-overview'>
            <div>
                <span>Category List</span>
            </div>
            <div className='user-info-overview-categories'>

                {
                    categories.map((category: any, index: any) => {
                        return (
                            <div key={index} className='user-info-overview-categories-item'>
                                <svg className='user-info-overview-categories-item-svg' xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <circle cx="5" cy="5" r="5" fill={categoryColors[index].color} />
                                </svg>
                                {/* { category.name } */}
                                {categoryColors[index].name}

                            </div>
                        );
                    })
                }
            </div>

            <div style={{paddingTop: "20px", paddingLeft: "10px"}}>
                <div>
                    <span>{statsHeaders[0]}</span>
                </div>
                <div className='user-info-overview-statistics'>
                    {dataMapper(statsHeaders[0])}
                    <div className='user-info-overview-statistics-item'>
                        <Pie {...config1} />
                    </div>
                </div>
                <div>
                    <span>{statsHeaders[1]}</span>
                </div>
                <div className='user-info-overview-statistics'>
                    {dataMapper(statsHeaders[1])}
                    <div className='user-info-overview-statistics-item'>
                        <Pie {...config2} />
                    </div>
                </div>
                <div>
                    <span>{statsHeaders[2]}</span>
                </div>
                <div className='user-info-overview-statistics'>
                    {dataMapper(statsHeaders[2])}
                    <div className='user-info-overview-statistics-item'>
                        <Pie {...config3} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserOverview;