import {
    SERVER_TIPS_USER_OVERVIEW_ENDPOINT
} from '../../config/endpoints';
import { axioService, GET } from '../../services/axiosService';
import { getAuthToken } from '../../services/authentication';
import { mapAuthorOverviewDataFromServerToClient } from './mapper';

class AuthorOverview {
    getUserOverview = async (page: number, username: string) => {
        const token = getAuthToken();

        const response = await axioService(
            GET,
            SERVER_TIPS_USER_OVERVIEW_ENDPOINT.replace(':username', username),
            {username, page},
            token
        );

        if (response) {
            return mapAuthorOverviewDataFromServerToClient(response?.data.data);
        }
    }
}

export default new AuthorOverview();