import { AudioOutlined, FilterTwoTone, UserOutlined } from '@ant-design/icons';
import { Input, Badge, Button, PageHeader, Select, Modal, message, Space, DatePicker, Dropdown } from 'antd'
import React, { useState, useEffect } from 'react'
import Category from '../../api/categories/request'
import './style.scss';
import Content from '../../api/content/request'
import { Pagination, Avatar } from 'antd';
import moment from 'moment'
import styles from './Contents.module.scss';
import { withRouter, useParams, useHistory, useLocation } from "react-router-dom";
import Layout from '../../components/layout/Layout';
import enUS from 'antd/lib/calendar/locale/en_US';
import { SERVER_TIPS_AUTHOR_VIEW_ENDPOINT, SERVER_TIPS_VIEW_ENDPOINT } from '../../config/endpoints';
import Column from 'antd/lib/table/Column';
import { TipsInterface } from '../../interfaces/tips.interface';

const { RangePicker }: any = DatePicker;

const Contents: React.FC = (props) => {
    const { TextArea } = Input;
    const history = useHistory();
    const location = useLocation();
    const [contents, setContents] = useState<TipsInterface[] | null | undefined>([]);
    const [categories, setCategories] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [slug, setSlug] = useState("");
    const [totalContent, setTotalContent] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [category, setCategory] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [status, setStatus] = useState('');
    const [fromNumber, setFromNumber] = useState(0);

    const [acceptModalVisible, setAcceptModalVisible] = useState(false);
    const [pendingModalVisible, setPendingModalVisible] = useState(false);
    const [contentPreviousStatus, setContentPreviousStatus] = useState("inactive");
    const [contentStatus, setContentStatus] = useState('inactive');
    const [contentLoader, setContentLoader] = useState(false);
    const [contentSlug, setContentSlug] = useState('');
    const [modalTextValue, setModalTextValue] = useState('');

    const success = (title: string) => {
        message.success(`${title} has been deleted!`);
      };

    const urlCategory:string = new URLSearchParams(location.search).get("category") || '';
    const range:string = new URLSearchParams(location.search).get("range") || '';
    const urlStartDate:any = new URLSearchParams(location.search).get("start_date") || '';
    const urlEndDate:any = new URLSearchParams(location.search).get("end_date") || '';
    const urlSearch:any = new URLSearchParams(location.search).get("q") || '';
    const urlStatus:any = new URLSearchParams(location.search).get("status") || '';
    const urlPageNumber: any = new URLSearchParams(location.search).get("page") || 1; 
    
    useEffect( () => {
        setCategory(urlCategory);
        setStatus(urlStatus);
        setFilter(filter);
        setPage(urlPageNumber);

        if(!urlStartDate && !urlEndDate) {
            setFilter('');
        }

       fetchContents();
    }, [page, location, contentLoader]);

    const fetchContents = async () => {
        try {
            const response = await Content.getContents(page, urlCategory, urlStartDate, urlEndDate, urlSearch, urlStatus );
            
            setContents(response?.data);
            console.log("contents : ",response?.data);
            setTotalContent(response?.total);
            setTotal(response?.total);
            setFromNumber(response?.from);
        } catch (error) {
            console.error(error)
        }
    }
    
    useEffect(()=> {
        Category.getCategories().then(res => {
            setCategories(res);
        }).catch(e => {
            console.log(e);
        });

    }, [])

    const onPageChange = (page: number)=> {
        history.push(`${location.pathname}?page=${page}${status ? `&status=${status}`: ""}${searchKey ? `&q=${searchKey}` : ""}${filter ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${category ? `&category=${category}` : ""}`);

        // let queryString = window.location.search;
        // let urlParams = new URLSearchParams(queryString);
        // let pageNumber = urlParams.get('page');
        // console.log("page number : ",pageNumber);

        setPage(page);
    }

    const viewContent = (id: string, slug: string) => {
        window.open(SERVER_TIPS_VIEW_ENDPOINT.replace(':id', id).replace(':slug', slug), '_blank');
    }

    const viewAuthor = (username: string) => {
        window.open(SERVER_TIPS_AUTHOR_VIEW_ENDPOINT.replace(':username', username), '_blank');
    }

    const deleteContent = (slug: string) => {
        setSlug(slug);
        showModal();
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setModalTextValue("");
    };

    const handleDelete = () => {
        Content.deleteContent(slug).then(res => {
            let updatedList: any = [];
            let title: string = "";
            contents?.map((content: any, index) => {
                if (content.tipsSlug !== slug) {
                    updatedList.push(content);
                } else {
                    title = content.tipsTitle;
                }
            });

            success(title);
            setTotalContent(totalContent - 1);
            setContents(updatedList);

        }).catch(e => {
            console.log(e);
        })
        setIsModalVisible(false);
    };

    const convertToDate = (range: string) => {
        const duration = range.split("-");
        let value = parseInt(duration[0]);
        let unit = duration[1];
        let days = 0;
        let totalDays;
        let startDate: any, endDate: any;

        switch (unit) {
            case 'day':
                days = 1;
                break;
            case 'week':
                days = 7;
                break;
            case 'month':
                days = 30;
                break;
            case 'year':
                days = 365;
                break;
            default:
                days = 1;
                break;
        }

        totalDays = days * value;

        startDate = new Date();
        endDate = new Date();

        startDate.setDate(startDate.getDate() - totalDays);

        startDate = moment(startDate).format("YYYY-MM-DD");
        endDate = moment(endDate).format("YYYY-MM-DD");

        return {
            startDate: startDate,
            endDate: endDate
        };
    }

    const onSelectStatus = (status: string) => {
        setStatus(status);
        
        if(status) {
            history.push(`${location.pathname}?status=${status}${searchKey ? `&q=${searchKey}` : ""}${filter ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${category ? `&category=${category}` : ""}`);
        }
    }

    const handleCategory = (category: string) => {
        setPage(1);
        setCategory(category);
        
        if(category) {
            history.push(`${location.pathname}?category=${category}${startDate ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${searchKey ? `&q=${searchKey}` : ""}${status ? `&status=${status}` : ""}`);
        }
    }

    const onSelectFilter = (filter: string) => {
        setFilter(filter);

        const dates = {
            startDate,
            endDate
        }

        if (filter !== 'custom') {
            const getDate = convertToDate(filter);
            dates.startDate = getDate.startDate;
            dates.endDate = getDate.endDate;
            setStartDate(dates.startDate);
            setEndDate(dates.endDate);
        }
        
        if(filter === "custom") {
            filter = '';
        }

        if(filter) {
            history.push(`${location.pathname}${filter ? `?start_date=${dates.startDate}` : ""}${dates.endDate ? `&end_date=${dates.endDate}` : ""} ${searchKey ? `&q=${searchKey}` : ""}${category ? `&category=${category}` : ""}${status ? `&status=${status}` : ""}`);
        }

        if (filter === 'clear') {
            setFilter('');

            if (category) {
                history.push(`${location.pathname}?category=${category}${searchKey ? `&q=${searchKey}` : ""}${status ? `&status=${status}` : ""}`);
            } if (searchKey) {
                history.push(`${location.pathname}?q=${searchKey}${category ? `&category=${category}` : ""}${status ? `&status=${status}` : ""}`);
            } if (status) {
                history.push(`${location.pathname}?status=${status}${searchKey ? `&q=${searchKey}` : ""}${category ? `&category=${category}` : ""}`);
            } else {
                history.push(`${location.pathname}`);
            }
        }
    }

    const onCalendarChange = (range:Date []) => {
        let convertedStartDate:any, convertedEndDate:any;
        
        convertedStartDate = moment(range[0]).format("YYYY-MM-DD");
        convertedEndDate = moment(range[1]).format("YYYY-MM-DD");

        setStartDate(convertedStartDate);
        setEndDate(convertedEndDate);

        if(filter) {
            history.push(`${location.pathname}${filter ? `?start_date=${convertedStartDate}` : ""}${convertedEndDate ? `&end_date=${convertedEndDate}` : ""} ${searchKey ? `&q=${searchKey}` : ""}${category ? `&category=${category}${status ? `&status=${status}` : ""}` : ""}`);
        }

    }

    const getInput = (searchKey:string) => {
        setPage(1);
        setSearchKey(searchKey); 
        if(searchKey) {
            history.push(`${location.pathname}${searchKey ? `?q=${searchKey}` : ""}${filter ? `&start_date=${startDate}${endDate ? `&end_date=${endDate}` : ""}` : ""}${category ? `&category=${category}` : ""}${status ? `&status=${status}` : ""}`);
        } else {
            if(category) {
                history.push(`${location.pathname}?category=${category}${startDate ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${searchKey ? `&q=${searchKey}` : ""}${status ? `&status=${status}` : ""}`);
            } if (filter) {
                history.push(`${location.pathname}${filter ? `?start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${category ? `&category=${category}` : ""}${status ? `&status=${status}` : ""}`);
            } if (status) {
                history.push(`${location.pathname}?status=${status}${searchKey ? `&q=${searchKey}` : ""}${filter ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}${category ? `&category=${category}` : ""}`);
            }
        }
    }

    const showAcceptModal = () => {
        setAcceptModalVisible(true);
    }

    const showPendingModal = () => {
        setPendingModalVisible(true);
    }

    const handleAcceptModalCancel = () => {
        setAcceptModalVisible(false);
    }

    const handlePendingModalCancel = () => {
        setPendingModalVisible(false);
        setModalTextValue("");
    }

    const handleStatus = (slug:any, status:string, previousStatus:string) => {
        setContentSlug(slug);
        setContentPreviousStatus(previousStatus);

        if (status === "active") {
            showAcceptModal();
            setContentStatus("active");
        }
        if (status === "inactive") {
            showPendingModal();
            setContentStatus("inactive");
        }
    }

    let contentUpdatedStatus;

    const handleContentActive = () => {
        Content.updateTipsStatus(contentSlug, contentStatus).then(response => {
            // console.log(response);
            contentUpdatedStatus = response.tipsStatus;

            if (contentPreviousStatus !== contentUpdatedStatus) {
                setContentLoader(!contentLoader);
            }
        }).catch(e => {
            console.log(e);
        })
        
        setAcceptModalVisible(false);
    }

    const handleContentInactive = () => {
        Content.updateTipsStatus(contentSlug, contentStatus).then( response => {
            // console.log(response);
            contentUpdatedStatus = response.tipsStatus;

            if (contentPreviousStatus !== contentUpdatedStatus) {
                setContentLoader(!contentLoader);
            }
        }).catch(e => {
            console.log(e);
        })

        setPendingModalVisible(false);
    }

    let previousLength = 0;
    
    const handleInput = (event:any) => {
        const bullet = "\u2022";
        const newLength = event.target.value.length;
        const characterCode = event.target.value.substr(-1).charCodeAt(0);

        if (newLength > previousLength) {
            if (characterCode === 10) {
                event.target.value = `${event.target.value}${bullet}  `;
            } else if (newLength === 1) {
                event.target.value = `${bullet} ${event.target.value}`
            }
        }

        previousLength = newLength;
        setModalTextValue(event.target.value)
    }

    console.log("modalTextValue : ",modalTextValue);

    const acceptModalFooter = (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <Button type='primary' size='large' onClick={handleContentActive}>
                Accept
            </Button>
        </div>
    )

    const pendingModalFooter = (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <Button type='primary' danger size='large' onClick={handleContentInactive}>
                Pending
            </Button>
        </div>
    )

    const footer = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" danger size='large' onClick={handleDelete}>
                Delete
            </Button>
        </div>
    )
    
    return (
        <Layout>
            <div className='contents'>
                <PageHeader title="List of Tips" />
                <div className='contents-header'>
                    <div className='contents-types'>
                        <Button type="primary">
                            Free
                        </Button>
                        <Button>
                            Premium
                        </Button>
                    </div>
                    <Select className='tips-select' value={status || "Status"} style={{ width: 182 }}
                        suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                        onSelect={onSelectStatus}
                    >
                        <Select.Option value="active">Approved</Select.Option>
                        <Select.Option value="inactive">Pending</Select.Option>
                    </Select>
                </div>
                <div className='contents-filter'>
                    <div className="contents-filter-left">

                    <Select onSelect={handleCategory} className='tips-select' value={category || "All category"} style={{ width: 182 }}
                        suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                    >
                            {
                                categories.map((category: any, index) => {
                                    return (
                                        <Select.Option key={category.uuid} value={category.slug}>{category.name}</Select.Option>
                                    );
                                })
                            }
                        </Select>
                        <Select className='tips-select' value={filter || "Filter"} style={{ width: 182 }}
                            suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            }
                            onSelect={onSelectFilter}
                        >
                            <Select.Option value="7-day">Last 7 days</Select.Option>
                            <Select.Option value="1-month">Last 1 month</Select.Option>
                            <Select.Option value="6-month">Last 6 months</Select.Option>
                            <Select.Option value="1-year">Last 1 year</Select.Option>
                            <Select.Option value="custom">Custom Date range</Select.Option>
                            <Select.Option value="clear">Clear Filters</Select.Option>
                        </Select>
                    </div>
                    {filter === "custom" && <RangePicker onCalendarChange={onCalendarChange} />}

                    <div className="contents-filter-right">
                        <span>Tips Count: {totalContent}</span>
                        <Input.Search onSearch={getInput} allowClear placeholder="input search text" style={{ width: 230 }}
                        />
                    </div>
                </div>
                <div className='contents-list'>
                    <div className='contents-list-header'>
                        <div className='contents-list-header-item'>Title</div>
                        <div className='contents-list-header-item'>Author</div>
                        <div className='contents-list-header-item'>Enrollment</div>
                        <div className='contents-list-header-item'>Publish Date</div>
                        <div className='contents-list-header-item'>Action</div>
                        <div className='contents-list-header-item-status'>Status</div>
                    </div>
                    <div className="contents-list-body">
                        {
                            contents?.map((content: any, index) => {
                                return (
                                    <div className='contents-list-body-item'>
                                        <div className="contents-list-item contents-list-name">
                                            <span className="contents-list-item-serial">
                                                { fromNumber + index+"." }
                                            </span>
                                            <span className="contents-list-item-title-span">
                                                <a className='contents-list-item-title' onClick={ () => viewContent(content.tipsId, content.tipsSlug) } >{ content.tipsTitle }</a>
                                            </span>
                                        </div>
                                        <div className="contents-list-item-username" onClick={ () => viewAuthor(content.authorUsername) }>
                                            <Avatar className='contents-list-item-avatar' size={36} src={content.authorAvatar} icon={<UserOutlined />}/>
                                            <span>
                                                { content.authorName.length > 20 ? content.authorName.slice(0,20)+'..' : content.authorName }
                                            </span>
                                        </div>
                                        <div className="contents-list-item-enrollment">{content.enrollmentCount}</div>
                                        <div className="contents-list-item-publish-date">{moment(content.publishedAt).format('DD MMM, YYYY')}</div>
                                        <div className="contents-list-item contents-list-action">
                                            <Button onClick={() => deleteContent(content.tipsSlug)}>
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.73438 1.95312H5.5625C5.65703 1.95312 5.73438 1.87578 5.73438 1.78125V1.95312H12.2656V1.78125C12.2656 1.87578 12.343 1.95312 12.4375 1.95312H12.2656V3.5H13.8125V1.78125C13.8125 1.02285 13.1959 0.40625 12.4375 0.40625H5.5625C4.8041 0.40625 4.1875 1.02285 4.1875 1.78125V3.5H5.73438V1.95312ZM16.5625 3.5H1.4375C1.05723 3.5 0.75 3.80723 0.75 4.1875V4.875C0.75 4.96953 0.827344 5.04688 0.921875 5.04688H2.21953L2.7502 16.2832C2.78457 17.0158 3.39043 17.5938 4.12305 17.5938H13.877C14.6117 17.5938 15.2154 17.018 15.2498 16.2832L15.7805 5.04688H17.0781C17.1727 5.04688 17.25 4.96953 17.25 4.875V4.1875C17.25 3.80723 16.9428 3.5 16.5625 3.5ZM13.7115 16.0469H4.28848L3.76855 5.04688H14.2314L13.7115 16.0469Z" fill="#FC3273" />
                                                </svg>
                                            </Button>
                                            <Modal title="Delete content" visible={isModalVisible} maskStyle={{ opacity: '0.3' }} footer={footer} onCancel={handleCancel}>
                                                <p>The content will be deleted from the system. Once the content is deleted it won’t be available in the following places</p>
                                                <ul style={{ paddingLeft: 15 }}>
                                                    <li>User’s personal profile</li>
                                                    <li>Content feed</li>
                                                    <li>System admin panel</li>
                                                </ul>
                                                <p>User will be notified that this particular content has been removed for going against the community guideline or terms and conditions.</p>
                                                <TextArea onInput = {handleInput} value={modalTextValue} showCount rows={4} placeholder="Terms and Conditions" maxLength={250}/>
                                            </Modal>
                                        </div>
                                        <div className="contents-list-item contents-list-status">
                                            <Select onSelect={ (status:any) => handleStatus(content.tipsSlug, status, content.status)} className='tips-select' value={content.tipsStatus === "active" ? "active" : "inactive"} style={{ width: 150 }} 
                                                suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.5" d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                            }>
                                                <Select.Option value="active">Approved</Select.Option>
                                                <Select.Option value="inactive">Pending</Select.Option>
                                            </Select>
                                        </div>
                                            <Modal title="Accept Tips" visible={acceptModalVisible} footer={acceptModalFooter} maskStyle={{ opacity: '0.3' }} onCancel={handleAcceptModalCancel}>
                                                <p>This content is available to all</p>
                                                <ul>
                                                    <li>1st Condition</li>
                                                    <li>2nd Condition</li>
                                                    <li>3rd Condition</li>
                                                </ul>
                                            </Modal>
                                            <Modal title="Cancel Tips request" visible={pendingModalVisible} footer={pendingModalFooter} onCancel={handlePendingModalCancel}>
                                                {/* <p>This content violate policy</p>
                                                <ul>
                                                    <li>1st Condition</li>
                                                    <li>2nd Condition</li>
                                                    <li>3rd Condition</li>
                                                </ul> */}
                                                <TextArea onInput = {handleInput} value={modalTextValue} showCount rows={4} placeholder="Terms and Conditions" maxLength={250}/>
                                            </Modal>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className={styles.PaginationContainer} >
                    <Pagination size="small" total={total}
                        defaultPageSize={15}
                        current={page}
                        onChange={onPageChange} />
                </div>
            </div>
        </Layout>
    )
}

export default Contents
