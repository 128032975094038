import React, {useState, useEffect} from "react";
import { UserOutlined } from '@ant-design/icons'
import { PageHeader, Button, Avatar, Select, Pagination, Input } from "antd";
import './style.scss';
import Layout from "../../components/layout/Layout";
import styles from '../Contents/Contents.module.scss';
import User from '../../api/users/request'
import { SERVER_TIPS_AUTHOR_VIEW_ENDPOINT } from '../../config/endpoints';
import { withRouter, useParams, Link } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";

interface Props {
    history: any;
    location: any;
}

function Users (props: Props) {
    const {} = props;
    const { history, location } = props;
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState()
    const [page, setPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [totalUser, setTotalUser] = useState(0);
    const [isEnableModalVisible, setIsEnableModalVisible] = useState(false);
    const [isDisableModalVisible, setIsDisableModalVisible] = useState(false);
    const [username, setUsername] = useState("");
    const [userStatus, setUserStatus] = useState(0);

    const [userPreviousStatus, setUserPreviousStatus] = useState(false);
    const [userCurrentStatus, setUserCurrentStatus] = useState(false);
    const [userLoader, setUserLoader] = useState(false);
    const urlSearch:any = new URLSearchParams(location.search).get("q") || '';
    const urlPageNumber:any = new URLSearchParams(location.search).get("page") || 1;

    useEffect ( () => {
        setPage(urlPageNumber);

        User.getUsers(page, urlSearch).then(res => {
            console.log(res.data);
            setUsers(res.data);
            setTotal(res.meta.total);
            setTotalUser(res.meta.total);
        }).catch(e => {
            console.log(e);
        });
    }, [page, userLoader, location]);

    const getInput = (searchKey: string) => {
        setPage(1);
        setSearchKey(searchKey);
        if(searchKey) {
            history.push(`${location.pathname}${searchKey ? `?q=${searchKey}` : ""}`);
        }
    }

    const onPageChange = (page:number) => {
        history.push(`${location.pathname}?page=${page}${searchKey ? `&q=${searchKey}`: ""}`);

        setPage(page);
    }

    const showIsEnableModal = () => {
        setIsEnableModalVisible(true);
    }

    const showIsDisableModal = () => {
        setIsDisableModalVisible(true);
    }

    const viewUserInfo = (username: string) => {
        window.open(SERVER_TIPS_AUTHOR_VIEW_ENDPOINT.replace(':username', username), '_blank');
    }

    const handleIsEnableModalCancel = () => {
        setIsEnableModalVisible(false);
    }

    const handleIsDisableModalCancel = () => {
        setIsDisableModalVisible(false);
    }

    const handleUserStatus = (username:string, status:string, userPreviousStatus:boolean) => {
        setUsername(username);
        setUserPreviousStatus(userPreviousStatus);

        if (status === "active") {
            showIsEnableModal();
            setUserStatus(1);
            setUserCurrentStatus(true);
        }
        if (status === "inactive") {
            showIsDisableModal();
            setUserStatus(0);
            setUserCurrentStatus(false);
        }
    }

    let userUpdateStatus;

    const handleEnable = () => {
        User.updateUserStatus(username, userStatus).then(res => {
            console.log(res.data);
            userUpdateStatus = res.data.is_active;

            if (userPreviousStatus !== userUpdateStatus) {
                setUserLoader(!userLoader);
            }

        }).catch(e => {
            console.log(e);
        });

        setIsEnableModalVisible(false);
    }

    const handleDisable = () => {
        User.updateUserStatus(username, userStatus).then(res => {
            console.log(res.data);
            userUpdateStatus = res.data.is_active;

            if (userPreviousStatus !== userUpdateStatus) {
                setUserLoader(!userLoader);
            }
            
        }).catch(e => {
            console.log(e);
        });

        setIsDisableModalVisible(false);
    }

    const enableFooter = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" size='large' onClick={handleEnable}>
                Enable
            </Button>
        </div>
    )

    const disableFooter = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" danger size="large" onClick={handleDisable}>
                Disable
            </Button>
        </div>
    )

    return (
        <Layout>
            <div className="users">
                <PageHeader title="List of Public Users" />
                <div className="users-header">
                    <div className="users-types">
                        <Button className="users-types-btn" type="primary">
                            General Accounts
                        </Button>
                        <Button className="users-types-btn">
                            Verified Accounts
                        </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="users-header-count">
                            <span className="users-header-count-span">Total number of accounts: {totalUser}</span>
                        </div>
                        <div>
                            <Input.Search onSearch={getInput} allowClear placeholder="input search text" style={{ width: 230 }}
                            />
                        </div>
                    </div>
                </div>
                <div className="users-list">
                    <div className="users-list-header">
                        <div className="user-list-header-item">Author Name</div>
                        <div className="user-list-header-item">Email</div>
                        <div className="user-list-header-item">Total Enrollment</div>
                        <div className="user-list-header-item">Total Unenrollment</div>
                        <div className="user-list-header-item">Check</div>
                        <div className="user-list-header-item">Action</div>
                    </div>
                    <div className="users-list-body">
                        {
                            users.map((user:any, index) => {
                                return (
                                    <div className="users-list-body-item">
                                        <div className="users-list-body-item-username">
                                            <Avatar className="users-list-body-item-avatar" src={user.avatar} size={36} icon={<UserOutlined/>}/>
                                            <span className="users-list-body-item-username-span">
                                                <Link className="users-list-body-item-username-link" to={'/user/details/' + user.username} >
                                                    {user.name.length > 20 ? user.name.slice(0, 20)+'..' : user.name} 
                                                </Link>
                                            </span>
                                        </div>
                                        <div className="users-list-body-item-email">
                                            <span>
                                                {user.email}
                                            </span>
                                        </div>
                                        <div className="users-list-body-item-enrollment">
                                            <span>
                                                {user.counts.enrolled_tips}
                                            </span>
                                        </div>
                                        <div className="users-list-body-item-unenrollment">
                                            <span>15</span>
                                        </div>
                                        <div className="users-list-body-item-check">
                                            <Button  onClick={ () => viewUserInfo(user.username) } className="users-list-body-item-btn users-list-body-item-check-button" type="primary">
                                                View
                                            </Button>
                                        </div>
                                        <div className="users-list-body-item-action">
                                            <Select onSelect={ (status:any) => handleUserStatus(user.username, status, user.is_active) } value={user.is_active === true ? "active" : "inactive"} className="users-list-select-action" defaultValue={"Status"}>
                                                <Select.Option value="active">Enable</Select.Option>
                                                <Select.Option value="inactive">Disable</Select.Option>
                                            </Select>
                                        </div>
                                            <Modal title="Enable User" visible={isEnableModalVisible} footer={enableFooter} maskStyle={{ opacity: '0.3' }} onCancel={handleIsEnableModalCancel}>
                                                <p>This user will be enable in system. Once the user is enabled it will be available in the following places</p>
                                                <ul>
                                                    <li>User’s personal profile</li>
                                                    <li>Content feed</li>
                                                </ul>
                                            </Modal>
                                            <Modal title="Disable User" visible={isDisableModalVisible} footer={disableFooter} maskStyle={{ opacity: '0.3' }} onCancel={handleIsDisableModalCancel}>
                                                <p>This user will be disabled from the system. Once the user is disabled it won’t be available in the following places</p>
                                                <ul>
                                                    <li>User’s personal profile</li>
                                                    <li>Content feed</li>
                                                </ul>
                                            </Modal>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className={styles.PaginationContainer}>
                    <Pagination size="small" total={total}
                        defaultPageSize={15}
                        current={page}
                        onChange={onPageChange}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default withRouter(Users);