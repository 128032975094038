import React, { createRef, useRef } from 'react';
import { useState, useEffect } from 'react';
import './CreatedTips.scss'
import AuthorsCreatedTips from '../../api/authorsCreatedTips/request'
import moment from 'moment';
import Content from '../../api/content/request';
import { Pagination, Modal, Button, Select, message, Input } from 'antd';
import {useHistory, useLocation} from "react-router-dom";
import styles from '../../pages/Contents/Contents.module.scss';
import { SERVER_TIPS_VIEW_ENDPOINT } from '../../config/endpoints';
import { url } from 'inspector';
import { TipsInterface } from '../../interfaces/tips.interface';


function CreatedTips({ userName }: { userName: string }) {
    const { TextArea } = Input;
    const history = useHistory();
    const location = useLocation();
    const [tipsList, setTipsList] = useState<TipsInterface[] | null | undefined>([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [fromNumber, setFromNumber] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [slug, setSlug] = useState('');

    const [acceptModalVisible, setAcceptModalVisible] = useState(false);
    const [pendingModalVisible, setPendingModalVisible] = useState(false);
    const [contentPreviousStatus, setContentPreviousStatus] = useState("inactive");
    const [contentStatus, setContentStatus] = useState('inactive');
    const [contentLoader, setContentLoader] = useState(false);
    const [contentSlug, setContentSlug] = useState('');
    const [modalTextValue, setModalTextValue] = useState('');

    const urlTab: string = new URLSearchParams(location.search).get("tab") || "tips";
    const urlPageNumber: any = new URLSearchParams(location.search).get("page") || 1;
    const statusInput:any = useRef(null);

    useEffect(() => {
        setPage(urlPageNumber);
        fetchCreatedTips();
    }, [page, contentLoader] );

    const fetchCreatedTips = async () => {
        try {
            const res = await AuthorsCreatedTips.getCreatedTips(userName, page);
            setTipsList(res?.data);
            setFromNumber(res?.from);
            setTotal(res?.total);
        } catch (error) {
            console.error(error);
        }
    }

    const onPageChange = (page: number)=> {
        history.push(`${location.pathname}${urlTab ? `?tab=${urlTab}` : ""}${page ? `&page=${page}` : 1}`);
        setPage(page);
    }

    const viewContent = (id: string, slug: string) => {
        window.open(SERVER_TIPS_VIEW_ENDPOINT.replace(':id', id).replace(':slug', slug), '_blank');
    }

    const showModal = () => {
        setIsModalVisible(true);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setModalTextValue("");
    }

    const deleteContent = (slug:string) => {
        setSlug(slug);
        showModal();
    }

    const handleDelete = () => {
        Content.deleteContent(slug).then(res => {
            const updateTipsList: any = [];
            let title: string = "";
            
            tipsList?.map((tips: any, index) => {
                if (tips.tipsSlug !== slug) {
                    updateTipsList.push(tips)
                } else {
                    title = tips.tipsTitle;
                }
            });

            success(title);
            setTotal(total-1);
            setTipsList(updateTipsList);
        }).catch(e => {
            console.log(e);
        })

        setIsModalVisible(false);
    }

    const success = (title: string) => {
        message.success(`${title} has been deleted!`);
    }

    const showAcceptModal = () => {
        setAcceptModalVisible(true);
    }

    const showPendingModal = () => {
        setPendingModalVisible(true);
    }

    const handleAcceptModalCancel = () => {
        setAcceptModalVisible(false);
    }

    const handlePendingModalCancel = () => {
        setPendingModalVisible(false);
        setModalTextValue("");
    }

    const handleStatus = (slug: string, status:string, previousStatus:string) => {
        setContentSlug(slug);
        setContentPreviousStatus(previousStatus);

        if (status === "active") {
            showAcceptModal();
            setContentStatus("active");
        }
        if (status === "inactive") {
            showPendingModal();
            setContentStatus("inactive");
        }
    }

    let contentUpdatedStatus;

    const handleContentActive = () => {
        Content.updateTipsStatus(contentSlug, contentStatus).then(response => {
            console.log(response);
            contentUpdatedStatus = response.tipsStatus;

            if (contentPreviousStatus !== contentUpdatedStatus) {
                setContentLoader(!contentLoader);
            }
        }).catch(e => {
            console.log(e);
        })
        
        setAcceptModalVisible(false);
    }

    const handleContentInactive = () => {
        Content.updateTipsStatus(contentSlug, contentStatus).then( response => {
            console.log(response);
            contentUpdatedStatus = response.tipsStatus;

            if (contentPreviousStatus !== contentUpdatedStatus) {
                setContentLoader(!contentLoader);
            }
        }).catch(e => {
            console.log(e);
        })

        setPendingModalVisible(false);
    }

    let previousLength = 0;
    
    const handleInput = (event:any) => {
        const bullet = "\u2022";
        const newLength = event.target.value.length;
        const characterCode = event.target.value.substr(-1).charCodeAt(0);
        
        if (newLength > previousLength) {
            if (characterCode === 10) {
                event.target.value = `${event.target.value}${bullet}  `;
            } else if (newLength === 1) {
                event.target.value = `${bullet}  ${event.target.value}`;
            }
        }

        previousLength = newLength;
        setModalTextValue(event.target.value)
    }

    console.log("value : ",modalTextValue);

    const acceptModalFooter = (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <Button type='primary' size='large' onClick={handleContentActive}>
                Accept
            </Button>
        </div>
    )

    const pendingModalFooter = (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <Button type='primary' danger size='large' onClick={handleContentInactive}>
                Pending
            </Button>
        </div>
    )

    const footer = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" danger size='large' onClick={handleDelete}>
                Delete
            </Button>
        </div>
    )

    return (
        <div className='user-created-tips'>
            <div className='user-created-tips-list user-created-tips-list-header'>
                <div className='user-created-tips-list-header-item'>Title</div>
                <div className='user-created-tips-list-header-item'>Category</div>
                <div className='user-created-tips-list-header-item'>Publish Date</div>
                <div className='user-created-tips-list-header-item-action'>Action</div>
                <div className='user-created-tips-list-header-item'>Status</div>
            </div>
            <div className='user-created-tips-list user-created-tips-list-body'>
                {
                    tipsList?.map((tips: any, index) => {
                        return (
                            <div className='user-created-tips-list-body-items'>
                                <div className='user-created-tips-list-body-item-title'>
                                    <span className="user-created-tips-list-body-item-title-serial">
                                        { fromNumber + index+"." }
                                    </span>
                                    <span className="user-created-tips-list-body-item-title-span">
                                        <a className='user-created-tips-list-item-title' onClick={ () => viewContent(tips.tipsId, tips.tipsSlug)}>
                                            {tips.tipsTitle}
                                        </a>
                                    </span>
                                </div>
                                <div className="user-created-tips-list-body-item-category">
                                    {tips.tipsCategory}
                                </div>
                                <div className="user-created-tips-list-body-item-publish-date">
                                    {moment(tips.publishedAt).format('DD MMM, YYYY')}
                                </div>
                                <div className="user-created-tips-list-body-item-action">
                                    <Button className="user-created-tips-list-body-item-action-button" onClick={() => deleteContent(tips.tipsSlug)}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.73438 1.95312H5.5625C5.65703 1.95312 5.73438 1.87578 5.73438 1.78125V1.95312H12.2656V1.78125C12.2656 1.87578 12.343 1.95312 12.4375 1.95312H12.2656V3.5H13.8125V1.78125C13.8125 1.02285 13.1959 0.40625 12.4375 0.40625H5.5625C4.8041 0.40625 4.1875 1.02285 4.1875 1.78125V3.5H5.73438V1.95312ZM16.5625 3.5H1.4375C1.05723 3.5 0.75 3.80723 0.75 4.1875V4.875C0.75 4.96953 0.827344 5.04688 0.921875 5.04688H2.21953L2.7502 16.2832C2.78457 17.0158 3.39043 17.5938 4.12305 17.5938H13.877C14.6117 17.5938 15.2154 17.018 15.2498 16.2832L15.7805 5.04688H17.0781C17.1727 5.04688 17.25 4.96953 17.25 4.875V4.1875C17.25 3.80723 16.9428 3.5 16.5625 3.5ZM13.7115 16.0469H4.28848L3.76855 5.04688H14.2314L13.7115 16.0469Z" fill="#FC3273" />
                                        </svg>
                                    </Button>
                                </div>
                                <div>
                                    <Select onSelect={ (status: any) => handleStatus(tips.tipsSlug, status, tips.tipsStatus) } value={tips.tipsStatus === "active" ? "active" : "inactive"} style={{ width: 150 }}
                                     suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                     <path opacity="0.5" d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                 </svg>
         }>
                                        <Select.Option value="active">Approved</Select.Option>
                                        <Select.Option value="inactive">Pending</Select.Option>
                                    </Select>
                                </div>
                                <Modal title="Delete content" visible={isModalVisible} maskStyle={{ opacity: '0.3' }} footer={footer} onCancel={handleCancel}>
                                    <p>The content will be deleted from the system. Once the content is deleted it won’t be available in the following places</p>
                                    <ul style={{ paddingLeft: 15 }}>
                                        <li>User’s personal profile</li>
                                        <li>Content feed</li>
                                        <li>System admin panel</li>
                                    </ul>
                                    <p>User will be notified that this particular content has been removed for going against the community guideline or terms and conditions.</p>
                                    <TextArea onInput = {handleInput} value={modalTextValue} showCount rows={4} placeholder="Terms and Conditions" maxLength={250}/>
                                </Modal>
                                <Modal title="Accept content" visible={acceptModalVisible} footer={acceptModalFooter} maskStyle={{ opacity: '0.3' }} onCancel={handleAcceptModalCancel}>
                                    <p>This content is available to all</p>
                                    <ul>
                                        <li>1st Condition</li>
                                        <li>2nd Condition</li>
                                        <li>3rd Condition</li>
                                    </ul>
                                    {/* <TextArea showCount rows={4} placeholder="maxLength is 6" maxLength={250}/> */}
                                </Modal>
                                <Modal title="Cancel content request" visible={pendingModalVisible} footer={pendingModalFooter} onCancel={handlePendingModalCancel}>
                                    {/* <p>This content violate policy</p>
                                    <ul>
                                        <li>1st Condition</li>
                                        <li>2nd Condition</li>
                                        <li>3rd Condition</li>
                                    </ul> */}
                                    <TextArea onInput = {handleInput} value={modalTextValue} showCount rows={4} placeholder="Terms and Conditions" maxLength={250}/>
                                </Modal>
                            </div>
                        )
                    })
                }
            </div>
            <div className={styles.PaginationContainer}>
                <Pagination size="small" total={total}
                    defaultPageSize={15}
                    current={page}
                    onChange={onPageChange} />
            </div>
        </div>
    )
}

export default CreatedTips;
