import {
    SERVER_TIPS_AUTHORS_CREATED_TIPS_ENDPOINT
} from '../../config/endpoints';
import { axioService, GET } from '../../services/axiosService';
import { getAuthToken } from '../../services/authentication';
import { mapTipsListFromServerToClient } from '../content/mapper';

class AuthorsCreatedTips {
    getCreatedTips = async (username: string, page: number) => {
        const token = getAuthToken();

        const response = await axioService(
            GET,
            SERVER_TIPS_AUTHORS_CREATED_TIPS_ENDPOINT.replace(':username', username),
            {page},
            token
        );
        
        if (response) {
            return {
              data: mapTipsListFromServerToClient(response?.data.data),
              total: response?.data.meta.total,
              from: response?.data.meta.from,
            };
          }
    };
}

export default new AuthorsCreatedTips();