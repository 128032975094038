import {
  SERVER_TIPS_ADMIN_CONTENT_ENDPOINT,
  SERVER_TIPS_ADMIN_TIPS_DELETE_ENDPOINT,
  SERVER_TIPS_STATUS_UPDATE_ENDPOINT,
  SERVER_TIPS_ADMIN_DELETED_TIPS_ENDPOINT,
  SERVER_TIPS_ADMIN_TIPS_RESTORE_ENDPOINT,
  SERVER_TIPS_ADMIN_TIPS_TRASH_ENDPOINT
} from '../../config/endpoints';
import { axioService, DELETE, GET, POST } from '../../services/axiosService';
import { getAuthToken } from '../../services/authentication';
import { mapTipsFromServerToClient, mapTipsListFromServerToClient, mapTrashedTipsListFromServerToClient } from './mapper';

class Content {
  getContents = async (page: number, category?: string, start_date?: Date, end_date?: Date, q?: string, status?: string) => {
    const token = getAuthToken();

    const response = await axioService(
      GET,
      SERVER_TIPS_ADMIN_CONTENT_ENDPOINT,
      { page, category, start_date, end_date, q, status },
      token
    );

    // return mapTipsFromServerToClient(response?.data.data, response?.data.meta.total, response?.data.meta.from);


    if (response) {
      return {
        data: mapTipsListFromServerToClient(response?.data.data),
        total: response?.data.meta.total,
        from: response?.data.meta.from,
      };
    }
  };

  deleteContent = async (slug: string) => {
    const token = getAuthToken();

    const response = await axioService(
      DELETE,
      SERVER_TIPS_ADMIN_TIPS_DELETE_ENDPOINT.replace(':slug', slug),
      {},
      token
    );

    return response?.data;
  }

  updateTipsStatus = async (slug:string, status:string) => {
    const token  = getAuthToken();

    const response = await axioService(
      POST,
      SERVER_TIPS_STATUS_UPDATE_ENDPOINT.replace(':slug', slug),
      { status },
      token
    );

    return mapTipsFromServerToClient(response?.data.data);
  }

  getDeletedTips = async (page: number, category: string) => {
    const token = getAuthToken();

    const response = await axioService(
      GET,
      SERVER_TIPS_ADMIN_DELETED_TIPS_ENDPOINT,
      { page, category },
      token
    );

    if (response) {
      return {
        data: mapTrashedTipsListFromServerToClient(response?.data.data),
        total: response?.data.meta.total,
        from: response?.data.meta.from,
      };
    }
  }

  restoreTips = async (slug: string) => {
    const token = getAuthToken();

    const response = await axioService(
      POST,
      SERVER_TIPS_ADMIN_TIPS_RESTORE_ENDPOINT.replace(':slug', slug),
      { slug },
      token
    );

    return response?.data;
  }

  trashTips = async(slug: string) => {
    const token = getAuthToken();

    const response = await axioService(
      POST,
      SERVER_TIPS_ADMIN_TIPS_TRASH_ENDPOINT.replace(':slug', slug),
      { slug },
      token
    );

    return response?.data;
  }
}

export default new Content();
