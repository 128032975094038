import React, {useState, useEffect} from "react";
import moment from 'moment';
import { Pagination, Avatar } from "antd";
import {useHistory, useLocation} from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import styles from '../../pages/Contents/Contents.module.scss';
import AuthorEnrolledTips from '../../api/authorEnrolledTips/request';
import { SERVER_TIPS_VIEW_ENDPOINT, SERVER_TIPS_AUTHOR_VIEW_ENDPOINT } from '../../config/endpoints';
import './EnrolledTips.scss';
import { TipsInterface } from "../../interfaces/tips.interface";

function EnrolledTips({ userName }: { userName:string }) {

    const history = useHistory();
    const location = useLocation();
    const [enrolledTipsList, setEnrolledTipsList] = useState<TipsInterface[] | null | undefined>([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [fromNumber, setFromNumber] = useState(0);
    const [type, setType] = useState('current');

    const urlTab: string = new URLSearchParams(location.search).get("tab") || "enrolled-tips";
    const urlPageNumber: any = new URLSearchParams(location.search).get("page") || 1;

    useEffect(() => {
        setPage(urlPageNumber);
        fetchEnrolledTips();
        }, [page]);
        
    const fetchEnrolledTips = async () => {
        try {
            const res = await AuthorEnrolledTips.getEnrolledTips(page, userName, type);
            setEnrolledTipsList(res?.data);
            setTotal(res?.total);
            setFromNumber(res?.from);
        } catch (error) {
            console.log(error);
        }
    }

    const onPageChange = (page:number) => {
        history.push(`${location.pathname}${urlTab ? `?tab=${urlTab}` : ""}${page ? `&page=${page}` : 1}`);
        
        setPage(page);
    }

    const viewContent = (id: string, slug: string) => {
        window.open(SERVER_TIPS_VIEW_ENDPOINT.replace(':id', id).replace(':slug', slug), '_blank');
    }

    const viewUserInfo = (username: string) => {
        window.open(SERVER_TIPS_AUTHOR_VIEW_ENDPOINT.replace(':username', username), '_blank');
    }

    return (
        <div className="users-enrolled-tips">
            <div className="users-enrolled-tips-list users-enrolled-tips-list-header">
                <div className="users-enrolled-tips-list-header-item">Title</div>
                <div className="users-enrolled-tips-list-header-item">Category</div>
                <div className="users-enrolled-tips-list-header-item">Author</div>
                <div className="users-enrolled-tips-list-header-item">Enrollment Date</div>
                <div className="users-enrolled-tips-list-header-item">Action</div>
            </div>
            <div className="users-enrolled-tips-list users-enrolled-tips-list-body">
                {
                    enrolledTipsList?.map((enrolledTips:any, index) => {
                        return (
                            <div className="users-enrolled-tips-list-body-item">
                                <div className="users-enrolled-tips-list-body-item-title">
                                    <span className="users-enrolled-tips-list-body-item-title-serial">
                                        { fromNumber + index+"."}
                                    </span>
                                    <span>
                                        <a className="users-enrolled-tips-list-body-item-title-span" onClick={ () => viewContent(enrolledTips.tipsId, enrolledTips.tipsSlug)}>
                                            {enrolledTips.tipsTitle}
                                        </a>
                                    </span>
                                </div>
                                <div className="users-enrolled-tips-list-body-item-category">
                                    <span>{enrolledTips.tipsCategory}</span>
                                </div>
                                <div className="users-enrolled-tips-list-body-item-author" onClick={ () => viewUserInfo(enrolledTips.authorUsername) }>
                                    <Avatar className="users-enrolled-tips-list-body-item-author-avatar" src={enrolledTips.authorAvatar} size={36} icon={<UserOutlined/>}/>
                                    <span>{enrolledTips.authorName.length > 20 ? enrolledTips.authorName.slice(0, 20)+'..' : enrolledTips.authorName}</span>
                                </div>
                                <div className="users-enrolled-tips-list-body-item-enrollment-date">
                                    <span>{moment(enrolledTips.last_enrollment?.start_at).format('DD MMM, YYYY')}</span>
                                </div>
                                <div className="users-enrolled-tips-list-body-item-action">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z" fill="#3161F1"/>
                                    </svg>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div className={styles.PaginationContainer}>
                <Pagination size="small" total={total}
                    defaultPageSize={15}
                    current={page}
                    onChange={onPageChange} />
            </div>
        </div>
    )
}

export default EnrolledTips;