const BASE_API = process.env.REACT_APP_BASE_API;
const ORIGIN = process.env.REACT_APP_ORIGIN_URL;
const AUTH_API = process.env.REACT_APP_AUTH_API;

export const SERVER_TIPS_ADMIN_PROFILE_ENDPOINT = `${BASE_API}/admin/profile`;
export const SERVER_TIPS_ADMIN_CONTENT_ENDPOINT = `${BASE_API}/admin/tips`;
export const SERVER_TIPS_ADMIN_CATEGORY_ENDPOINT = `${BASE_API}/admin/categories`;
export const SERVER_TIPS_ADMIN_TIPS_DELETE_ENDPOINT = `${BASE_API}/admin/tips/:slug/delete`;
export const SERVER_TIPS_ADMIN_TIPS_RESTORE_ENDPOINT = `${BASE_API}/admin/tips/:slug/restore`;
export const SERVER_TIPS_ADMIN_TIPS_TRASH_ENDPOINT = `${BASE_API}/admin/tips/:slug/trash`;
export const SERVER_TIPS_STATUS_UPDATE_ENDPOINT = `${BASE_API}/admin/tips/:slug/status`;
export const SERVER_TIPS_USER_ENDPOINT = `${BASE_API}/admin/authors`;
export const SERVER_TIPS_USER_STATUS_UPDATE_ENDPOINT = `${BASE_API}/admin/authors/:username/status`
export const SERVER_TIPS_USER_ENROLLED_TIPS_ENDPOINT = `${BASE_API}/admin/authors/:username/enrolled-tips`;
export const SERVER_TIPS_USER_UNENROLLED_TIPS_ENDPOINT = `${BASE_API}/admin/authors/:username/unenrolled-tips`;
export const SERVER_TIPS_AUTHORS_CREATED_TIPS_ENDPOINT = `${BASE_API}/admin/authors/:username/created-tips`;
export const SERVER_TIPS_USER_OVERVIEW_ENDPOINT = `${BASE_API}/admin/authors/:username/overview`
export const SERVER_TIPS_USER_DETAILS_ENDPOINT = `${BASE_API}/admin/authors/:username`;
export const SERVER_TIPS_ADMIN_DELETED_TIPS_ENDPOINT = `${BASE_API}/admin/deleted-tips`;
export const SERVER_TIPS_USER_PROFILE_ENDPOINT = `${BASE_API}/admin/authors/:username`;
export const SERVER_ACCOUNT_AVATAR_CHANGE_ENDPOINT = `${AUTH_API}/profile/change-avatar`;
export const SERVER_TIPS_ADMIN_LOGOUT_ENDPOINT = `${AUTH_API}/logout`;

export const SERVER_TIPS_VIEW_ENDPOINT = `${ORIGIN}/tips/:id/:slug`;
export const SERVER_TIPS_AUTHOR_VIEW_ENDPOINT = `${ORIGIN}/authors/:username`;

