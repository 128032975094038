import * as React from "react";
import { useEffect } from "react"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Layout from "./components/layout/Layout";
import { isAuthenticated } from "./services/authentication";
import Contents from "./pages/Contents";
import Users from "./pages/Users";
import UserDetails from "./pages/Users/UserDetails";
import TrashedTips from "./pages/TrashedTips";
import FreeContents from "./pages/FreeContents";
import Home from "./pages/Home";
import NoMatch from "./pages/NoMatch";
import { UserInterface } from "./interfaces/user.interface";
import MyProfile from "./pages/MyProfile";


export default function App() {

  if(!isAuthenticated()) {
    window.location.replace("https://devaccounts.virtunus.com/login?app=staging.admin");
  }
  
  return (
      <div>
        <Router>
          <Switch>
            <Route path="/contents">
              <Contents />
            </Route>
            <Route path="/trash">
              <TrashedTips />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/user/details/:userName">
              <UserDetails />
            </Route>
            <Route path="/myProfile">
                <MyProfile />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
  );
}