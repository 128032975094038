import {
    SERVER_TIPS_ADMIN_PROFILE_ENDPOINT
  } from '../../config/endpoints';
import { axioService, GET, POST } from '../../services/axiosService';
import { getAuthToken } from '../../services/authentication';
import { mapAdmnProfileFromServerToClient } from './mapper';
  
class AdminProfile {
  getAdmin = async () => {
    const token = getAuthToken();

    const response = await axioService(
      GET,
      SERVER_TIPS_ADMIN_PROFILE_ENDPOINT,
      {},
      token
    );

    if (response) {
      return mapAdmnProfileFromServerToClient(response?.data);
    }
  };
}
  
  export default new AdminProfile();
  